import { Paginator } from '@app/classes';
import { QueryFetcher } from '@app/models/core/query-fetcher.model';
import { TaxReturn } from '@app/models/payroll/tax-return.model';
import { Table } from '@app/modules/table/classes/table.class';
import { AuthService } from '@app/services';
import { T4Summary } from '@models/payroll/t4-summary.model';
import { filter, take } from 'rxjs/operators';
import { GeneratedOnColumn, StatusColumn, TitleColumn } from './columns';

/**
 * An instance of Paginator that contains only unpersisted data. This is necessary for
 * users to be able to click into new tax returns, from where they will be able to
 * generate and persist them.
 *
 * We can add more / different records to this array as needed.
 */
export class NonGeneratedTaxReturnPaginator<T extends T4Summary | TaxReturn> extends Paginator<T> {
    constructor(private _staticValues: T[]) {
        super(new QueryFetcher(null));
    }

    get total(): number {
        return this._staticValues.length;
    }

    get empty(): boolean {
        return false;
    }

    get values(): T[] {
        return this._staticValues;
    }
}

export class TaxReturnsTable extends Table<TaxReturn> {
    // TODO: PAY-3059 allow links to generate new TaxReturn model
    links = false;
    constructor(
        auth: AuthService,
        private year: number
    ) {
        super(auth);
        this.boot();
    }

    setColumns(): void {
        this.columns = [new TitleColumn(this.year), new GeneratedOnColumn(), new StatusColumn()];
    }

    /**
     * Attempt to load the TaxReturn for the year.
     *
     * If the index is empty, swap the paginator for an instance of NonGeneratedTaxReturnPaginator,
     * so that we have our default row(s) in the table
     */
    setDataSource(): void {
        this.onLoading
            .pipe(
                filter((loading) => !loading),
                take(1)
            )
            .subscribe(() => {
                if (this.paginator?.empty) {
                    this.paginator = new NonGeneratedTaxReturnPaginator([new TaxReturn()]);
                }
            });

        this.paginator = new Paginator(TaxReturn.where('year', this.year.toString()));
    }
}
