<div class="snack-bar-message">
    <mat-icon *ngIf="data.icon" [svgIcon]="data.icon"></mat-icon>
    <span>{{ data.key ?? '' | translate: data.params ?? {} }}</span>
    @if (data.action) {
        @if (isFunction(data.action.onClick)) {
            <ui-button type="outline" size="stripped" class="margin-left-4" (click)="data.action.onClick()">{{
                data.action.text ?? '' | translate
            }}</ui-button>
        } @else {
            <a [routerLink]="data.action.onClick">{{ data.action.text ?? '' | translate }}</a>
        }
    }
</div>
