import { SimpleEntityActions } from '@app/classes';
import { TerminationCode, TerminationCodes } from '@app/constants/termination-codes';
import { GendersCollection } from '@app/interfaces';
import { PronounsCollection } from '@app/interfaces/pronouns.interface';
import { SaveOptions } from '@app/interfaces/save-options.interface';
import { HiringAccessLevelIdsEnum } from '@app/modules/applicant-tracker/enums/hiring-access-levels.enum';
import { LegalSex } from '@app/types/legal-sex.type';
import { BankAccount } from '@models/employee/bank-account.model';
import { CustomField } from '@models/employee/custom-field.model';
import { EmployeeNamePronunciation } from '@models/employee/employee-name-pronunciation.model';
import { LeaveOfAbsence } from '@models/employee/leave-of-absence.model';
import { OnboardingVideo } from '@models/employee/onboarding-video.model';
import { Ytd } from '@models/payroll/ytd.model';
import { DataField } from '@models/settings/data-field.model';
import { Task } from '@models/tasks/task.model';
import { WorkSchedule } from '@models/time-off-v3/works-schedule.model';
import { endOfToday, parse } from 'date-fns';
import moment from 'moment';
import { Account } from '../account/account.model';
import { EmployeeModuleAssignment } from '../common/employee-module-assignment';
import { Company } from '../company/company.model';
import { Department } from '../company/department.model';
import { Job } from '../company/job.model';
import { Office } from '../company/office.model';
import { Model } from '../core/base.model';
import { ConnectEmployeeLink } from '../recruiting/connect-employee-link.model';
import { TimeOffPolicy } from '../time-off-v3/time-off-policy.model';
import { ProjectAssignment } from '../time-tracking/project-assignment.model';
import { ProjectOwnership } from '../time-tracking/project-ownership.model';
import { Address } from './address.model';
import { BenefitPlan } from './benefits/benefit-plan.model';
import { Reminder } from './reminder.model';
import { Salary } from './salary.model';

/**
 * The Employee Model represents a user's employment in a company
 * In some ways, it's better to think of it as an employment record
 *
 * An Account can have multiple Employees, but only one per company
 */
export class Employee extends Model {
    static birthdayPermission = new SimpleEntityActions('employee.birthday', ['view']);

    static legalSexPermission = new SimpleEntityActions('employee.legalSex', ['view', 'update']);

    static importPermission = 'import.employee';
    static eventFeedAnniversariesPermission = 'eventFeedAnniversaries';

    // these permissions will be renamed
    static sinPermission = new SimpleEntityActions('accountDetails.sin', ['view', 'update']);
    static bornOnPermission = new SimpleEntityActions('accountDetails.bornOn', ['view', 'update']);

    static manageLeaveOfAbsencePermission = 'manageLeaveOfAbsence';

    protected static _resource = 'companyManagement/companies/:company/employees';
    protected static _version = 'v2';
    protected static _dates = ['hiredAt', 'terminatedOn', 'bornOn', 'lastDayOfWorkOn', 'nextDayOfWorkOn'];
    protected static _serializeAttributes = [
        'accountId',
        'additionalTerms',
        'addressId',
        'atsApplicantId',
        'offerLetterId',
        'avatarId',
        'bornOn',
        'collectHiringQuestions',
        'companyId',
        'currency',
        'departmentId',
        'dietaryRestrictions',
        'ecPrimaryName',
        'ecPrimaryPhone',
        'ecPrimaryRelation',
        'ecSecondaryName',
        'ecSecondaryPhone',
        'ecSecondaryRelation',
        'employmentType',
        'firstName',
        'gender',
        'legalSex',
        'hiredAt',
        'hoursPerWeek',
        'isBirthdayPrivate',
        'isPayrollSyncEnabled',
        'isSinRequired',
        'jobId',
        'language',
        'lastDayOfWorkOn',
        'lastName',
        'nextDayOfWorkOn',
        'officeId',
        'workScheduleId',
        'paymentMethod',
        'phoneHome',
        'phoneMobile',
        'phoneWork',
        'reportsTo',
        'sin',
        'status',
        'stockOptions',
        'taxInformation',
        'terminatedOn',
        'terminationComments',
        'terminationReasonCode',
        'wasAddedByTerminationFlow',
        'welcomeMessage',
        'vacationPayMethod',
        'genders',
        'pronouns',
    ];

    private _accessLevelId = null;

    private _scheduledForFuturePolicyAssignment = false;

    get accessLevelId(): HiringAccessLevelIdsEnum {
        return this._accessLevelId;
    }

    set accessLevelId(val: HiringAccessLevelIdsEnum) {
        this._accessLevelId = val;
    }

    get fullName(): string {
        return this.firstName + ' ' + this.lastName;
    }

    get isBirthdayPrivate(): boolean {
        return this._attributes['isBirthdayPrivate'];
    }

    set isBirthdayPrivate(val: boolean) {
        this._attributes['isBirthdayPrivate'] = val;
    }

    /**
     * Whether Payroll sync is turned on for this employee.
     *
     * To check if the employee is actively syncing, see `Employee.activeOnPayroll()`.
     */
    get isPayrollSyncEnabled(): boolean {
        return this._attributes['isPayrollSyncEnabled'];
    }

    set isPayrollSyncEnabled(val: boolean) {
        this._attributes['isPayrollSyncEnabled'] = val;
        this._attributes['isSinRequired'] = this._attributes['isPayrollSyncEnabled'];
    }

    get isSinRequired(): boolean {
        return this._attributes['isSinRequired'];
    }

    set isSinRequired(val: boolean) {
        this._attributes['isSinRequired'] = val;
    }

    get documentTemplateAssignment(): any {
        return this._attributes['documentTemplateAssignment'] || {};
    }

    get wasAddedByTerminationFlow(): boolean {
        return this._attributes['wasAddedByTerminationFlow'];
    }

    /**
     * The employee's ID in Payroll.
     *
     * If you're just checking the truthiness of this value, you
     * probably want to be using `Employee.activeOnPayroll()`
     */
    get prEmployeeId(): number | null {
        return this._attributes['prEmployeeId'] || null;
    }

    set prEmployeeId(val: number) {
        this._attributes['prEmployeeId'] = val;
    }

    get prJobId(): number | null {
        return this._attributes['prJobId'] || null;
    }

    set prJobId(val: number) {
        this._attributes['prJobId'] = val;
    }

    get hiredAt(): Date | null {
        return this._attributes['hiredAt'] || null;
    }

    set hiredAt(val: Date) {
        this._attributes['hiredAt'] = val;
    }

    /**
     * lengthOfServiceFormatted is a string in the format `2y 8m 21d`
     * it represents the amount of time an employee has worked at a company
     * if it is undefined it is not calculated
     * if it is null, it is calculated and invalid
     */
    private lengthOfServiceFormatted: string | null | undefined;

    get lengthOfService(): string | null {
        if (this.lengthOfServiceFormatted === undefined) {
            this.calculateLengthOfService();
        }

        return this.lengthOfServiceFormatted;
    }

    get accountId(): number | null {
        return this._attributes['accountId'] || this._attributes['account_id'] || null;
    }

    set accountId(val: number | null) {
        this._attributes['accountId'] = val;
    }

    get companyId(): number | null {
        return this._attributes['companyId'] || this._attributes['company_id'] || null;
    }

    set companyId(val: number | null) {
        this._attributes['companyId'] = val;
    }

    get officeId(): number | null {
        return this._attributes['officeId'] || null;
    }

    set officeId(val: number | null) {
        this._attributes['officeId'] = val;
    }

    get departmentId(): number | null {
        return this._attributes['departmentId'] || null;
    }

    set departmentId(val: number | null) {
        this._attributes['departmentId'] = val;
    }

    get reportsTo(): number | null {
        return this._attributes['reportsTo'] || null;
    }

    set reportsTo(val: number | null) {
        this._attributes['reportsTo'] = val;
    }

    get scheduledForFuturePolicyAssignment(): boolean {
        return this._scheduledForFuturePolicyAssignment;
    }

    set scheduledForFuturePolicyAssignment(val: boolean) {
        this._scheduledForFuturePolicyAssignment = val;
    }

    get reports(): Employee[] | null {
        return this._attributes['reports'] || null;
    }

    set reports(val: Employee[] | null) {
        this._attributes['reports'] = val;
    }

    get jobId(): number | null {
        return this._attributes['jobId'] || null;
    }

    set jobId(val: number | null) {
        this._attributes['jobId'] = val;
    }

    get positionId(): number | null {
        return this._attributes['jobId'] || null;
    }

    set positionId(val: number | null) {
        this._attributes['jobId'] = val;
    }

    get timeOffPolicies(): TimeOffPolicy[] {
        return this.hasMany(TimeOffPolicy, 'activeTimeOffPolicies');
    }

    get allAssignedTimeOffPolicies(): TimeOffPolicy[] {
        return this.hasMany(TimeOffPolicy, 'allAssignedTimeOffPolicies');
    }

    get timeTrackingProjectAssignments(): ProjectAssignment[] {
        return this.hasMany(ProjectAssignment, 'timeTrackingProjectAssignments');
    }

    get activeTimeTrackingProjectAssignments(): ProjectAssignment[] {
        return this.hasMany(ProjectAssignment, 'activeTimeTrackingProjectAssignments');
    }

    get timeTrackingProjectAssignmentsUserCanEnterHoursFor(): ProjectAssignment[] {
        return this.hasMany(ProjectAssignment, 'timeTrackingProjectAssignmentsUserCanEnterHoursFor');
    }

    get hasPendingTimeTrackingHours(): boolean | null {
        return this._attributes['hasPendingTimeTrackingHours'] ?? null;
    }

    set hasPendingTimeTrackingHours(val: boolean) {
        this._attributes['hasPendingTimeTrackingHours'] = val;
    }

    /**
     * currentUserSupervisesTimeTracking is only available on the auth employee
     */
    get currentUserSupervisesTimeTracking(): boolean | null {
        return this._attributes['currentUserSupervisesTimeTracking'] ?? null;
    }

    get hasTimeTrackingAdminPermission(): boolean | null {
        return this._attributes['hasTimeTrackingAdminPermission'] ?? null;
    }

    get atsApplicantId(): number | null {
        return parseInt(this._attributes['atsApplicantId']) || null;
    }

    set atsApplicantId(val: number | null) {
        this._attributes['atsApplicantId'] = val;
    }

    get offerLetterId(): number | null {
        return this._attributes['offerLetterId'] || null;
    }

    set offerLetterId(val: number | null) {
        this._attributes['offerLetterId'] = val;
    }

    get firstName(): string | null {
        return this._attributes['firstName'] || null;
    }

    set firstName(val: string | null) {
        this._attributes['firstName'] = val;
    }

    get lastName(): string | null {
        return this._attributes['lastName'] || null;
    }

    set lastName(val: string | null) {
        this._attributes['lastName'] = val;
    }

    get phoneWork(): string | null {
        return this._attributes['phoneWork'] || null;
    }

    set phoneWork(val: string | null) {
        this._attributes['phoneWork'] = val;
    }

    get paymentMethod(): string | null {
        return this._attributes['paymentMethod'] || null;
    }

    set paymentMethod(val: string | null) {
        this._attributes['paymentMethod'] = val;
    }

    get employmentTypeId(): number | null {
        return this._attributes['employmentTypeId'] || null;
    }

    /*
     * What you pass here is not saved by the BE, the EmployeeObserver on the BE
     * will override the id based on what it gets for employmentType.
     *
     * There is a house-keeping ticket to address this duplication of data.
     * https://gethumi.atlassian.net/browse/HK-508
     */
    set employmentTypeId(val: number | null) {
        this._attributes['employmentTypeId'] = val;
    }

    get employmentType(): string | null {
        return this._attributes['employmentType'] || null;
    }

    set employmentType(val: string | null) {
        this._attributes['employmentType'] = val;
    }

    get hoursPerWeek(): number | null {
        return this._attributes['hoursPerWeek'] || null;
    }

    set hoursPerWeek(val: number | null) {
        this._attributes['hoursPerWeek'] = val;
    }

    get stockOptions(): string | null {
        return this._attributes['stockOptions'] || null;
    }

    set stockOptions(val: string | null) {
        this._attributes['stockOptions'] = val;
    }

    get additionalTerms(): string | null {
        return this._attributes['additionalTerms'] || null;
    }

    set additionalTerms(val: string | null) {
        this._attributes['additionalTerms'] = val;
    }

    get status(): 'active' | 'onboarding' | 'terminated' | null {
        return this._attributes['status'] || null;
    }

    set status(val: 'active' | 'onboarding' | 'terminated' | null) {
        this._attributes['status'] = val;
    }

    get ecPrimaryName(): string | null {
        return this._attributes['ecPrimaryName'] || null;
    }

    set ecPrimaryName(val: string | null) {
        this._attributes['ecPrimaryName'] = val;
    }

    get ecPrimaryRelation(): string | null {
        return this._attributes['ecPrimaryRelation'] || null;
    }

    set ecPrimaryRelation(val: string | null) {
        this._attributes['ecPrimaryRelation'] = val;
    }

    get ecPrimaryPhone(): string | null {
        return this._attributes['ecPrimaryPhone'] || null;
    }

    set ecPrimaryPhone(val: string | null) {
        this._attributes['ecPrimaryPhone'] = val;
    }

    get ecSecondaryName(): string | null {
        return this._attributes['ecSecondaryName'] || null;
    }

    set ecSecondaryName(val: string | null) {
        this._attributes['ecSecondaryName'] = val;
    }

    get ecSecondaryRelation(): string | null {
        return this._attributes['ecSecondaryRelation'] || null;
    }

    set ecSecondaryRelation(val: string | null) {
        this._attributes['ecSecondaryRelation'] = val;
    }

    get ecSecondaryPhone(): string | null {
        return this._attributes['ecSecondaryPhone'] || null;
    }

    set ecSecondaryPhone(val: string | null) {
        this._attributes['ecSecondaryPhone'] = val;
    }

    get legalSex(): LegalSex {
        return this._attributes['legalSex'] || null;
    }

    set legalSex(val: LegalSex) {
        this._attributes['legalSex'] = val;
    }

    get language(): string | null {
        return this._attributes['language'] || null;
    }

    set language(val: string | null) {
        this._attributes['language'] = val;
    }

    get phoneHome(): string | null {
        return this._attributes['phoneHome'] || null;
    }

    set phoneHome(val: string | null) {
        this._attributes['phoneHome'] = val;
    }
    get phoneMobile(): string | null {
        return this._attributes['phoneMobile'] || null;
    }

    set phoneMobile(val: string | null) {
        this._attributes['phoneMobile'] = val;
    }

    get sin(): string | null {
        return this._attributes['sin'] || null;
    }

    set sin(val: string | null) {
        this._attributes['sin'] = val;
    }

    get dietaryRestrictions(): string | null {
        return this._attributes['dietaryRestrictions'] || null;
    }

    set dietaryRestrictions(val: string | null) {
        this._attributes['dietaryRestrictions'] = val;
    }

    get bornOn(): Date | null {
        return this._attributes['bornOn'];
    }

    set bornOn(val: Date | null) {
        this._attributes['bornOn'] = val;
    }

    get age(): number | null {
        if (!this.bornOn) {
            return null;
        }

        const diff = moment().diff(this.bornOn);
        return moment.duration(diff).years();
    }

    get terminatedOn(): string | null {
        return this._attributes['terminatedOn'] || null;
    }

    set terminatedOn(val: string | null) {
        this._attributes['terminatedOn'] = val;
    }

    get lastDayOfWorkOn(): string | null {
        return this._attributes['lastDayOfWorkOn'] || null;
    }

    set lastDayOfWorkOn(val: string | null) {
        this._attributes['lastDayOfWorkOn'] = val;
    }

    get terminationReasonCode(): TerminationCode | null {
        return this._attributes['terminationReasonCode'] || null;
    }

    get terminationReason(): string | null {
        if (!this.terminationReasonCode) {
            return null;
        }

        return TerminationCodes[this.terminationReasonCode];
    }

    set terminationReasonCode(val: TerminationCode | null) {
        this._attributes['terminationReasonCode'] = val;
    }

    get terminationComments(): string | null {
        return this._attributes['terminationComments'] || null;
    }

    set terminationComments(val: string | null) {
        this._attributes['terminationComments'] = val;
    }

    get welcomeMessage(): string | null {
        return this._attributes['welcomeMessage'] || null;
    }

    set welcomeMessage(val: string | null) {
        this._attributes['welcomeMessage'] = val;
    }

    get collectHiringQuestions(): boolean | null {
        return !!this._attributes['collectHiringQuestions'] || null;
    }

    set collectHiringQuestions(val: boolean | null) {
        this._attributes['collectHiringQuestions'] = val;
    }

    get currency(): string | null {
        return this._attributes['currency'] || null;
    }

    set currency(val: string | null) {
        this._attributes['currency'] = val;
    }

    get bankAccountId(): number {
        return this._attributes['bankAccountId'] || null;
    }

    get taxInformationId(): number {
        return this._attributes['taxInformationId'] || null;
    }

    get avatarId(): number {
        return this._attributes['avatarId'];
    }

    set avatarId(val: number) {
        this._attributes['avatarId'] = val;
    }

    get birthMonth(): number {
        return this._attributes['birthMonth'];
    }

    get birthDay(): number {
        return this._attributes['birthDay'];
    }

    get account(): Account {
        return this.hasOne(Account, 'account');
    }

    get addressId(): number {
        return this._attributes['addressId'];
    }

    set addressId(val: number) {
        this._attributes['addressId'] = val;
    }

    get workScheduleId(): number {
        return this._attributes['workScheduleId'];
    }

    set workScheduleId(val: number | null) {
        this._attributes['workScheduleId'] = val;
    }

    get vacationPayMethod(): string {
        return this._attributes['vacationPayMethod'];
    }

    set vacationPayMethod(val: string) {
        this._attributes['vacationPayMethod'] = val;
    }

    get address(): Address {
        return this.hasOne(Address, 'address');
    }

    set address(address: Address) {
        this.setOne('address', address, 'addressId');
    }

    get assignedTasks(): Task[] {
        return this.hasMany(Task, 'assignedTasks');
    }

    get job(): Job | null {
        return this.hasOne(Job, 'job');
    }

    set job(job: Job) {
        this.setOne('job', job, 'jobId');
    }

    get connectEmployeeLink(): ConnectEmployeeLink {
        return this.hasOne(ConnectEmployeeLink, 'connectEmployeeLink');
    }

    set connectEmployeeLink(value: ConnectEmployeeLink) {
        this.setOne('connectEmployeeLink', value);
    }

    get position(): Job {
        return this.job;
    }

    set position(val: Job) {
        this.job = val;
    }

    /**
     * Not all employees have creators.
     * The first employee in a company will not have a creator.
     * Employees created before we started recording creator will not have a creator.
     */
    get creator(): Employee | null {
        return this.hasOne(Employee, 'creator');
    }

    /**
     * Not all employees have managers.
     */
    get manager(): Employee | null {
        return this.hasOne(Employee, 'manager');
    }

    set manager(manager: Employee) {
        this.setOne('manager', manager, 'reportsTo');
    }

    get peers(): Employee[] {
        return this.hasMany(Employee, 'peers').filter((peer: Employee) => peer.id !== this.id);
    }

    get activePeers(): Employee[] {
        return this.hasMany(Employee, 'activePeers').filter((peer: Employee) => peer.id !== this.id);
    }

    get directReports(): Employee[] {
        return this.hasMany(Employee, 'directReports');
    }

    get activeDirectReports(): Employee[] {
        return this.hasMany(Employee, 'activeDirectReports');
    }

    get department(): Department | null {
        return this.hasOne(Department, 'department');
    }

    set department(department: Department) {
        this.setOne('department', department, 'departmentId');
    }

    get company(): Company {
        return this.hasOne(Company, 'company');
    }

    get office(): Office | undefined {
        return this.hasOne(Address, 'office');
    }

    set office(office: Office) {
        this.setOne('office', office, 'officeId');
    }

    get customFields(): CustomField[] {
        return this.hasMany(CustomField, 'customFields');
    }

    get dataFields(): DataField[] {
        return this.hasMany(DataField, 'dataFields');
    }

    get reminders(): Reminder[] {
        return this.hasMany(Reminder, 'reminders', {
            employee: this.id,
        });
    }

    get salary(): Salary {
        if (!this.activeSalary && this.salaries.length) {
            this.activeSalary = this.findActiveSalary();
        }
        return this.activeSalary;
    }

    get salaries(): Salary[] {
        return this.hasMany(Salary, 'salaries');
    }

    set salaries(val: Salary[]) {
        this.setMany('salaries', val);
    }

    get bankAccount(): BankAccount {
        if (!this.bankAccounts.length) {
            return this.tempBankAccount;
        }
        return this.bankAccounts[0];
    }

    set bankAccount(val: BankAccount) {
        this.setOne('bankAccount', val);
    }

    get bankAccounts(): BankAccount[] {
        return this.hasMany(BankAccount, 'bankAccounts', {
            employee: this.id,
        });
    }

    get onLeave(): boolean {
        return this._attributes['onLeave'];
    }

    get leaveOfAbsences(): LeaveOfAbsence[] {
        return this.hasMany(LeaveOfAbsence, 'leaveOfAbsences');
    }

    set bankAccounts(val: BankAccount[]) {
        this.setMany('bankAccounts', val);
    }

    get taxInformation(): any {
        return this._attributes['taxInformation'];
    }

    set taxInformation(val: any) {
        this._attributes['taxInformation'] = val;
    }

    get simplyBenefitsUserId(): null | string {
        return this._attributes['simplyBenefitsUserId'];
    }

    get hasSimplyBenefits(): boolean {
        return this.simplyBenefitsUserId ? true : false;
    }

    get ytd(): any {
        return this.hasOne(Ytd, 'ytd');
    }

    set ytd(val: any) {
        this.setOne('ytd', val);
    }

    get activeTimeOffPolicies(): TimeOffPolicy[] {
        return this.hasMany(TimeOffPolicy, 'activeTimeOffPolicies');
    }

    get activeModuleAssignments(): EmployeeModuleAssignment[] {
        return this.hasMany(EmployeeModuleAssignment, 'activeModuleAssignments');
    }

    get activeTimeTrackingProjectOwnerships(): ProjectOwnership[] {
        return this.hasMany(ProjectOwnership, 'activeTimeTrackingProjectOwnerships');
    }

    get benefitPlans(): BenefitPlan[] {
        return this.hasMany(BenefitPlan, 'benefitPlans');
    }

    get workSchedule(): WorkSchedule {
        return this.hasOne(WorkSchedule, 'workSchedule');
    }

    get offerAcceptedDate(): Date | null {
        return this._attributes['offerAcceptedDate'] || null;
    }

    set offerAcceptedDate(val: Date) {
        this._attributes['offerAcceptedDate'] = val;
    }

    get nextDayOfWorkOn(): Date {
        return this._attributes['nextDayOfWorkOn'];
    }

    set nextDayOfWorkOn(val: Date) {
        this._attributes['nextDayOfWorkOn'] = val;
    }

    /**
     * isOnboardingPending means the employee is onboarding,
     * but no activation link has been sent
     */
    get isOnboardingPending(): boolean {
        return this._attributes['isOnboardingPending'];
    }

    /**
     * Since Genders field is not a required value, genders could be empty.
     * But, instead of returning an [], we're returning 'null' to avoid rendering issues.
     */
    get genders(): GendersCollection | null {
        return this._attributes['genders'] ?? null;
    }

    set genders(val: GendersCollection) {
        this._attributes['genders'] = val;
    }

    /**
     * Since Pronouns field is not a required value, pronouns could be empty.
     * But, instead of returning an [], we're returning 'null' to avoid rendering issues.
     */
    get pronouns(): PronounsCollection | null {
        return this._attributes['pronouns'] ?? null;
    }

    set pronouns(val: PronounsCollection) {
        this._attributes['pronouns'] = val;
    }

    get onboardingVideo(): OnboardingVideo | null {
        return this._attributes['onboardingVideo'] ?? null;
    }

    get employeeNamePronunciation(): EmployeeNamePronunciation {
        return this.hasOne(EmployeeNamePronunciation, 'employeeNamePronunciation');
    }

    set employeeNamePronunciation(pronunciation: EmployeeNamePronunciation) {
        // We store the model for refresh without setting ID as this is a belongsTo
        this.setOne('employeeNamePronunciation', pronunciation);
    }

    static permission = new SimpleEntityActions('companyEmployee');
    private activeSalary: Salary = null;
    private tempBankAccount: BankAccount = new BankAccount();

    async loadSalaries(): Promise<void> {
        const [salaries] = await Salary.param('company', this.companyId).param('employee', this.id).all();

        this.salaries = salaries;
        this.activeSalary = this.findActiveSalary();
    }

    canBeLoggedInAs(): boolean {
        return this.status !== 'terminated';
    }

    doesNotReportToAnyone(): boolean {
        if (this.reportsToThemselves()) {
            this.reportsTo = null;
        }

        return !this.reportsTo;
    }

    /**
     * This is an invalid state
     * If an employee is found to report to themselves it
     * is most likely a migration issue
     */
    reportsToThemselves(): boolean {
        return Number(this.reportsTo) === Number(this.id);
    }

    reportsToEmployee(employee: Employee): boolean {
        return this.reportsTo === employee.id;
    }

    isActive(): boolean {
        return this.status === 'active';
    }

    isTerminated(): boolean {
        return this.status === 'terminated';
    }

    isOnboarding(): boolean {
        return this.status === 'onboarding';
    }

    /**
     * Can only return true if the employee has an upcoming termination/rehire
     * and the current user is allowed to know about it (as dictated by the api)
     */
    hasUpcomingTermination(): boolean {
        return Boolean(this.lastDayOfWorkOn && moment(this.lastDayOfWorkOn).isAfter(moment()));
    }
    hasUpcomingRehire(): boolean {
        return Boolean(this.nextDayOfWorkOn && moment(this.nextDayOfWorkOn).isAfter(moment()));
    }

    hasBenefitPlan(): boolean {
        return this.benefitPlans.length > 0;
    }

    /**
     * Whether the employee is actively syncing to Payroll.
     */
    activeOnPayroll(): boolean {
        return this.isPayrollSyncEnabled && !!this.prEmployeeId;
    }

    // employee uses patch
    save(options: Partial<SaveOptions> = {}) {
        return super.save({ ...{ onlyDirty: true }, ...options });
    }

    /**
     * hasModule checks if the employee is assigned to a module
     * For this to work, the active module assignments must be loaded.
     */
    hasModule(moduleName: string): boolean {
        return this.activeModuleAssignments.some((m) => m.module.name === moduleName);
    }

    /**
     * Determine the active salary based on effective date
     * @return {number} [description]
     */
    private findActiveSalary(): Salary {
        if (!this.salaries.length) {
            return null;
        }

        const endOfTodayTime = endOfToday().getTime();

        // Filter to only salaries before or on today
        const pastSalaries = this.salaries.filter((salary) => {
            const compareDate = parse(salary.effectiveAt);
            return compareDate.getTime() <= endOfTodayTime;
        });

        if (!pastSalaries.length) {
            return null;
        }

        // We can assume that the first salary is the most recently effective as the response from the server is in order.
        return pastSalaries[0];
    }

    private calculateLengthOfService(): void {
        const dateToCompare = this.lastDayOfWorkOn ?? moment();
        const diff = moment(dateToCompare).diff(this.hiredAt);
        const duration = moment.duration(diff);

        if (duration.milliseconds() < 0) {
            this.lengthOfServiceFormatted = null;

            return;
        }

        let lengthOfService = '';

        if (duration.years() > 0) {
            lengthOfService += duration.years() + 'y ';
        }

        if (duration.months() > 0) {
            lengthOfService += duration.months() + 'm ';
        }

        if (duration.days() > 0) {
            lengthOfService += duration.days() + 'd ';
        }

        this.lengthOfServiceFormatted = lengthOfService.trim();
    }
}
