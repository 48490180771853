import { NgTemplateOutlet } from '@angular/common';
import { Component, input, signal, WritableSignal } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { AvatarComponent } from '@app/components/avatar/avatar.component';
import { ButtonComponent } from '@app/components/platform';
import { ModelUnion } from '@app/models/core/base.model';
import { TranslatableKey } from '@app/types/translatable.type';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';

/**
 * The three buttons displayed as possible actions to the user.
 * If additional buttons are added to this screen, they can be added here
 */
type Action = 'BACK' | 'CONFIRM' | 'CANCEL';

@Component({
    selector: 'app-bulk-select-confirmation',
    templateUrl: './bulk-select-confirmation.template.html',
    styleUrls: ['./bulk-select-confirmation.style.scss'],
    standalone: true,
    imports: [TranslateModule, MatIconModule, NgTemplateOutlet, ButtonComponent, AvatarComponent],
})
export class BulkSelectConfirmation {
    // Translatable text within the confirmation screen
    header = input<TranslatableKey>('components.bulk-select-confirmation.defaults.header');
    additionsText = input<TranslatableKey>('components.bulk-select-confirmation.defaults.additions');
    removalsText = input<TranslatableKey>('components.bulk-select-confirmation.defaults.removals');
    translationParams = input<Parameters<TranslatePipe['transform']>[1]>({});

    // The employees to be added/removed
    additions: WritableSignal<ModelUnion[]> = signal([]);
    removals: WritableSignal<ModelUnion[]> = signal([]);

    private _onActionPerformed = new Subject<Action>();

    /**
     * Emits when an action is performed by the user, this is subscribed to by the base dialog to know when the user wants to navigate away from the confirmation dialog
     */
    get onActionPerformed(): Observable<Action> {
        return this._onActionPerformed.asObservable();
    }

    /**
     * One of the three buttons (back, confirm, cancel) has been clicked
     */
    performAction(action: Action): void {
        this._onActionPerformed.next(action);
    }

    /**
     * Some of the translation parameters to be interpolated into the template
     */
    getTranslationParams(employees: ModelUnion[]): Parameters<TranslatePipe['transform']>[1] {
        return { count: employees.length, ...this.translationParams() };
    }
}
