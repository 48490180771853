import { BadgeDisplay } from '@app/modules/table/interfaces/badge-display.interface';
import { BadgeType } from '@humi-design-library/components/Badge/types';
import { ModelMixin } from '@models/core/base-generic.model';

export type Status = 'open';

export class TaxReturn extends ModelMixin<TaxReturn>() {
    protected static _resource = 'payroll/taxReturns';

    protected static _version = 'v2';

    protected static _datetimes = ['createdAt', 'updatedAt'];

    get name(): string {
        return this._attributes['name'];
    }
    set name(val: string) {
        this._attributes['name'] = val;
    }

    get date(): string {
        return this._attributes['date'];
    }
    set date(val: string) {
        this._attributes['date'] = val;
    }

    get year(): number {
        return this._attributes['year'];
    }
    set year(val: number) {
        this._attributes['year'] = val;
    }

    get isAmendment(): boolean {
        return this._attributes['isAmendment'];
    }
    set isAmendment(val: boolean) {
        this._attributes['isAmendment'] = val;
    }

    get status(): Status {
        return this._attributes['status'];
    }
    set status(val: Status) {
        this._attributes['status'] = val;
    }

    get createdAt(): string {
        return this._attributes['createdAt'];
    }
    set createdAt(val: string) {
        this._attributes['createdAt'] = val;
    }

    get updatedAt(): string {
        return this._attributes['updatedAt'];
    }
    set updatedAt(val: string) {
        this._attributes['updatedAt'] = val;
    }

    get stateBadge(): BadgeDisplay {
        if (this.createdAt === undefined) {
            return { label: 'payroll.taxReturns.taxReturnStatuses.notGenerated', type: 'default' };
        }

        const label = `payroll.taxReturns.taxReturnStatuses.${this.status}`;

        return { label, type: this.status ? this._stateBadgeMap[this.status] : 'default' };
    }

    /**
     * maps each of the possible Statuses to a badge display type
     */
    private _stateBadgeMap: Record<Status, BadgeType> = {
        open: 'informative',
    };
}
