import { Paginator, SortDirection } from '@app/classes';
import { T4 } from '@app/models/payroll/t4.model';
import { Table } from '@app/modules/table/classes/table.class';
import { AuthService } from '@app/services';
import { DisplayPendingStateColumn } from './columns/display-pending-state.column';
import { EmployeeColumn } from './columns/employee.column';
import { LastModifiedColumn } from './columns/last-modified.column';
import { StatusColumn } from './columns/status.column';

const pageSize = 10;

export class T4IndexTable extends Table<T4> {
    sortDirection = SortDirection.ASC;
    links = true;
    protected sortProperty = 'lastName';

    constructor(
        protected auth: AuthService,
        public year: number,
        public t4SummaryId?: number
    ) {
        super(auth);
        this.boot();
    }

    setColumns(): void {
        const employeeColumn = new EmployeeColumn();
        this.sortColumn = employeeColumn;
        this.columns = [employeeColumn, new LastModifiedColumn(), new StatusColumn(), new DisplayPendingStateColumn()];
    }

    setDataSource(): void {
        const query = T4.param('company', this.auth.company.id).where('year', this.year.toString());

        if (this.t4SummaryId) {
            query.where('t4_summary_id', this.t4SummaryId);
        }

        this.paginator = new Paginator(query.limit(pageSize), {
            pageSize: pageSize,
        });
    }

    getLink(t4: T4): string[] {
        return ['/payroll/records/tax-forms/t4-show', t4.id.toString()];
    }
}
