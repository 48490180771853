import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AssignedDentalBenefitEmployee } from '@app/models/payroll/assigned-dental-benefit-employee.model';
import { AuthService } from '@app/services';
import { environment } from '@env/environment';
import { Serializer } from 'jsonapi-serializer';
import { from, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { DentalBenefitCode, DentalBenefitCodeCount } from './types';

/**
 * Service for making all API requests related to box 45 dental benefit codes
 */
@Injectable()
export class DentalBenefitService {
    /**
     * This is used for cache-busting of the root DentalBenefitsTable. It's provided as a service via dependency injection, therefore it's not re-created between visits.
     * This has the advantage of not making API calls to re-retrieve the data when nothing has changed, however, we need some way of telling the table to reload the data after employees have been re-assigned.
     */
    haveCountChangesOccurred = false;

    private readonly DENTAL_BENEFIT_CODE_COUNT_ENDPOINT = `/v2/payroll/companies/${this.authService.company.id}/box45OptionsWithEmployeeCount`;

    constructor(
        private authService: AuthService,
        private httpClient: HttpClient
    ) {}

    /**
     * Retrieves dental benefit code employee count from the back-end
     * @returns A map of each code to the number of assigned employees (ie. {1: 4, 2: 0, 3: 0, ...})
     */
    getAssignedDentalBenefitCodeCounts(): Observable<DentalBenefitCodeCount> {
        return this.makeHttpGETRequest<DentalBenefitCodeCount>(this.DENTAL_BENEFIT_CODE_COUNT_ENDPOINT);
    }

    /**
     * Retrieves a list of all employees assigned to a dental benefit code.
     * This is necessary before opening the bulk assign dialog so that we can provide the component with which employees are already assigned
     */
    getAllEmployeesAssignedToDentalBenefitCode(code: DentalBenefitCode): Observable<AssignedDentalBenefitEmployee[]> {
        return from(
            AssignedDentalBenefitEmployee.param('company', this.authService.company.id)
                .where('option', code)
                .limit(0)
                .all()
        ).pipe(map(([employees]) => employees) /* unwrap unneeded paginator data */);
    }

    /**
     * bulk attaches and detaches employees from a dental benefit code
     */
    attachAndDetachEmployees(
        code: DentalBenefitCode,
        attachments: { attach?: string[]; detach?: string[] }
    ): Observable<void> {
        // Avoiding having to create a Box45 model here instead we can just define the shape of the body we want and call the JSON serializer
        const body = new Serializer('box45', {
            keyForAttribute: 'camelCase',
            pluralizeType: false,
            attributes: Object.keys(attachments),
        }).serialize({ attach: { employees: attachments.attach }, detach: { employees: attachments.detach } });

        return this.makeHttpPUTRequest(this.DENTAL_BENEFIT_ATTACH_ENDPOINT(code), body).pipe(
            tap(() => (this.haveCountChangesOccurred = true)) // Cache bust after successfully applying changes
        );
    }

    private DENTAL_BENEFIT_ATTACH_ENDPOINT = (code: DentalBenefitCode): string =>
        `/v2/payroll/companies/${this.authService.company.id}/box45/${code}/attachDetachEmployees`;

    /**
     * Makes a GET request to the back-end
     */
    private makeHttpGETRequest<T>(url: string): Observable<T> {
        return this.httpClient.get<T>(`${environment.api}${url}`, {
            observe: 'body',
            headers: { 'Content-Type': 'application/json' },
        });
    }

    /**
     * Makes a PUT request to the back-end
     */
    private makeHttpPUTRequest<T>(url: string, body: T): Observable<T> {
        return this.httpClient.put<T>(`${environment.api}${url}`, body, {
            observe: 'body',
            headers: { 'Content-Type': 'application/json' },
        });
    }
}
