import { TaxReturn } from '@app/models/payroll/tax-return.model';
import { BadgeColumn } from '@app/modules/table/classes/badge-column.class';
import { Column } from '@app/modules/table/classes/column.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';
import { BadgeDisplay } from '@app/modules/table/interfaces/badge-display.interface';
import { Translatable } from '@app/types/translatable.type';

export class TitleColumn extends Column {
    type = CellDisplay.string;
    title = 'table.taxReturns.taxReturn';

    constructor(private year: number) {
        super();
    }

    getDisplayPropertyValue(object: TaxReturn): Translatable {
        return {
            key: object.isAmendment ? 'payroll.taxReturns.annualReturnAmended' : 'payroll.taxReturns.annualReturn',
            params: { year: this.year },
        };
    }
}

export class GeneratedOnColumn extends Column {
    type = CellDisplay.date;
    property = 'createdAt';
    title = 'table.taxReturns.generatedOn';
}

export class StatusColumn extends BadgeColumn<TaxReturn> {
    type = CellDisplay.badge;
    title = 'table.taxReturns.status';

    getBadgeDisplay(row: TaxReturn): BadgeDisplay {
        return row.stateBadge;
    }
}
