import { BadgeOptions } from '@app/interfaces';
import { EmployeeDetails } from '@app/interfaces/employee-details.interface';
import { Model } from '@models/core/base.model';
import { parse } from 'date-fns';
import moment from 'moment';
import { Employee } from '../employee/employee.model';

type T4State = 'open' | 'edited' | 'error' | 'processing' | 'complete';

export class T4 extends Model {
    protected static _resource = 'payroll/companies/:company/t4s';

    protected static _version = 'v2';

    protected static _serializeAttributes = [
        // Generic Attributes
        'name',
        'date',
        'employeeId',
        'employeeDetails',
        'address1',
        'address2',
        'address3',
        'locality',
        'adminArea',
        'country',
        'postCode',
        'createdAt',
        'updatedAt',
        'defaultContact',
        'year',
        'employeeId',
        'rptTcd',

        // T4 Boxes
        'emptIncamt',
        'cppCntrbAmt',
        'qppCntrbAmt',
        'empeEipAmt',
        'itxDdctAmt',
        'eiInsuErnAmt',
        'cppQppErnAmt',
        'hmBrdLodgAmt',
        'spclWrkSiteAmt',
        'prscbZnTrvlAmt',
        'medTrvlAmt',
        'prsnlVhclAmt',
        'lowIntLoanAmt',
        'sobA00FebAmt',
        'sodDA00FebAmt',
        'othTxBenAmt',
        'sodD1A00FebAmt',
        'emptCmsnAmt',
        'cfppaAmt',
        'elgRtirAmt',
        'nelgRtirAmt',
        'indnNelgRtirAmt',
        'indnEmpeAmt',
        'pr90CntrbrAmt',
        'pr90NcntrbrAmt',
        'cmpnRpayEmprAmt',
        'fishGroErnAmt',
        'fishNetPtnrAmt',
        'fishShrPrsnAmt',
        'plcmtEmpAgcyAmt',
        'drvrTaxisOthAmt',
        'brbrHrdrssrAmt',
        'epaidHlthPlnAmt',
        'stokOptCshOutEamt',
        'vlntrEmergencyworkerXmptAmt',
        'indnTxmptSeiAmt',
        'emptIncAmtCovidPrd1',
        'emptIncAmtCovidPrd2',
        'emptIncAmtCovidPrd3',
        'emptIncAmtCovidPrd4',
        'rppCntrbAmt',
        'padjAmt',
        'provPipAmt',
        'provInsuErnAmt',
        'unnDuesAmt',
        'chrtyDonsAmt',
        'cppQppXmptCd',
        'rppDpspRgstNbr',
        'emprDntlBenRptCd',
    ];

    protected static _dates = ['modifiedAt'];

    isOriginal(): boolean {
        return this.rptTcd === 'O';
    }

    /**
     * Whether the T4 is in an editable state
     */
    isOpen(): boolean {
        return !['processing', 'complete'].includes(this.state);
    }

    isAmended(): boolean {
        return this.rptTcd === 'A';
    }

    /**
     * General Attributes
     */
    get id(): number {
        return +this._attributes['id'];
    }
    get name(): string {
        return this._attributes['name'];
    }
    get date(): string {
        return this._attributes['date'];
    }
    get year(): number {
        return moment(this.date).year();
    }
    get modifiedAt(): Date {
        return this._attributes['modifiedAt'];
    }
    get state(): T4State {
        return this._attributes['state'];
    }
    get employeeId(): number {
        return +this._attributes['employeeId'];
    }
    get craFormT4SummaryId(): string {
        return this._attributes['craFormT4SummaryId'];
    }
    get address1(): string {
        return this._attributes['address1'];
    }
    get address2(): string {
        return this._attributes['address2'];
    }
    get address3(): string {
        return this._attributes['address3'];
    }
    get locality(): string {
        return this._attributes['locality'];
    }
    get adminArea(): string {
        return this._attributes['adminArea'];
    }
    get country(): string {
        return this._attributes['country'];
    }
    get postCode(): string {
        return this._attributes['postCode'];
    }
    get terminated(): boolean {
        return this._attributes['terminated'];
    }
    get firstName(): string {
        return this._attributes['employeeDetails'].firstName;
    }
    get lastName(): string {
        return this._attributes['employeeDetails'].lastName;
    }
    get fullName(): string {
        return this.firstName + ' ' + this.lastName;
    }
    get sin(): string {
        return this._attributes['employeeDetails'].sin;
    }
    get rptTcd(): string {
        return this._attributes['rptTcd'];
    }

    get employeeDetails(): EmployeeDetails {
        return this._attributes['employeeDetails'];
    }
    get employee(): Employee {
        return this.hasOne(Employee, 'employee');
    }

    set name(val: string) {
        this._attributes['name'] = val;
    }
    set year(val: number) {
        this._attributes['year'] = val;
    }
    set employeeId(val: number) {
        this._attributes['employeeId'] = val;
    }
    set id(val: number) {
        this._attributes['id'] = val;
    }
    set address1(val: string) {
        this._attributes['address1'] = val;
    }
    set address2(val: string) {
        this._attributes['address2'] = val;
    }
    set address3(val: string) {
        this._attributes['address3'] = val;
    }
    set locality(val: string) {
        this._attributes['locality'] = val;
    }
    set adminArea(val: string) {
        this._attributes['adminArea'] = val;
    }
    set country(val: string) {
        this._attributes['country'] = val;
    }
    set postCode(val: string) {
        this._attributes['postCode'] = val;
    }
    set firstName(val: string) {
        this._attributes['employeeDetails'].firstName = val;
    }
    set lastName(val: string) {
        this._attributes['employeeDetails'].lastName = val;
    }
    set sin(val: string) {
        this._attributes['employeeDetails'].sin = val;
    }
    set defaultContactName(val: string) {
        if (!this._attributes['defaultContact']) {
            this._attributes['defaultContact'] = {};
        }

        this._attributes['defaultContact']['name'] = val;
    }
    set defaultContactEmail(val: string) {
        if (!this._attributes['defaultContact']) {
            this._attributes['defaultContact'] = {};
        }

        this._attributes['defaultContact']['email'] = val;
    }
    set defaultContactPhone(val: string) {
        if (!this._attributes['defaultContact']) {
            this._attributes['defaultContact'] = {};
        }

        this._attributes['defaultContact']['phone_number'] = val;
    }

    /**
     * T4 Boxes
     */

    get emptIncamt(): number {
        return this._attributes['emptIncamt'];
    }
    get cppCntrbAmt(): number {
        return this._attributes['cppCntrbAmt'];
    }
    get qppCntrbAmt(): number {
        return this._attributes['qppCntrbAmt'];
    }
    get empeEipAmt(): number {
        return this._attributes['empeEipAmt'];
    }
    get itxDdctAmt(): number {
        return this._attributes['itxDdctAmt'];
    }
    get eiInsuErnAmt(): number {
        return this._attributes['eiInsuErnAmt'];
    }
    get cppQppErnAmt(): number {
        return this._attributes['cppQppErnAmt'];
    }
    get hmBrdLodgAmt(): number {
        return this._attributes['hmBrdLodgAmt'];
    }
    get spclWrkSiteAmt(): number {
        return this._attributes['spclWrkSiteAmt'];
    }
    get prscbZnTrvlAmt(): number {
        return this._attributes['prscbZnTrvlAmt'];
    }
    get medTrvlAmt(): number {
        return this._attributes['medTrvlAmt'];
    }
    get prsnlVhclAmt(): number {
        return this._attributes['prsnlVhclAmt'];
    }
    get lowIntLoanAmt(): number {
        return this._attributes['lowIntLoanAmt'];
    }
    get sobA00FebAmt(): number {
        return this._attributes['sobA00FebAmt'];
    }
    get sodDA00FebAmt(): number {
        return this._attributes['sodDA00FebAmt'];
    }
    get othTxBenAmt(): number {
        return this._attributes['othTxBenAmt'];
    }
    get sodD1A00FebAmt(): number {
        return this._attributes['sodD1A00FebAmt'];
    }
    get emptCmsnAmt(): number {
        return this._attributes['emptCmsnAmt'];
    }
    get cfppaAmt(): number {
        return this._attributes['cfppaAmt'];
    }
    get elgRtirAmt(): number {
        return this._attributes['elgRtirAmt'];
    }
    get nelgRtirAmt(): number {
        return this._attributes['nelgRtirAmt'];
    }
    get indnNelgRtirAmt(): number {
        return this._attributes['indnNelgRtirAmt'];
    }
    get indnEmpeAmt(): number {
        return this._attributes['indnEmpeAmt'];
    }
    get pr90CntrbrAmt(): number {
        return this._attributes['pr90CntrbrAmt'];
    }
    get pr90NcntrbrAmt(): number {
        return this._attributes['pr90NcntrbrAmt'];
    }
    get cmpnRpayEmprAmt(): number {
        return this._attributes['cmpnRpayEmprAmt'];
    }
    get fishGroErnAmt(): number {
        return this._attributes['fishGroErnAmt'];
    }
    get fishNetPtnrAmt(): number {
        return this._attributes['fishNetPtnrAmt'];
    }
    get fishShrPrsnAmt(): number {
        return this._attributes['fishShrPrsnAmt'];
    }
    get plcmtEmpAgcyAmt(): number {
        return this._attributes['plcmtEmpAgcyAmt'];
    }
    get drvrTaxisOthAmt(): number {
        return this._attributes['drvrTaxisOthAmt'];
    }
    get brbrHrdrssrAmt(): number {
        return this._attributes['brbrHrdrssrAmt'];
    }
    get epaidHlthPlnAmt(): number {
        return this._attributes['epaidHlthPlnAmt'];
    }
    get stokOptCshOutEamt(): number {
        return this._attributes['stokOptCshOutEamt'];
    }
    get vlntrEmergencyworkerXmptAmt(): number {
        return this._attributes['vlntrEmergencyworkerXmptAmt'];
    }
    get indnTxmptSeiAmt(): number {
        return this._attributes['indnTxmptSeiAmt'];
    }
    get emptIncAmtCovidPrd1(): number {
        return this._attributes['emptIncAmtCovidPrd1'];
    }
    get emptIncAmtCovidPrd2(): number {
        return this._attributes['emptIncAmtCovidPrd2'];
    }
    get emptIncAmtCovidPrd3(): number {
        return this._attributes['emptIncAmtCovidPrd3'];
    }
    get emptIncAmtCovidPrd4(): number {
        return this._attributes['emptIncAmtCovidPrd4'];
    }
    get rppCntrbAmt(): number {
        return this._attributes['rppCntrbAmt'];
    }
    get padjAmt(): number {
        return this._attributes['padjAmt'];
    }
    get provPipAmt(): number {
        return this._attributes['provPipAmt'];
    }
    get provInsuErnAmt(): number {
        return this._attributes['provInsuErnAmt'];
    }
    get unnDuesAmt(): number {
        return this._attributes['unnDuesAmt'];
    }
    get chrtyDonsAmt(): number {
        return this._attributes['chrtyDonsAmt'];
    }
    get cppQppXmptCd(): number {
        return this._attributes['cppQppXmptCd'];
    }
    get rppDpspRgstNbr(): string {
        return this._attributes['rppDpspRgstNbr'];
    }
    get displayPendingState(): boolean {
        return this._attributes['displayPendingState'];
    }

    get emprDntlBenRptCd(): string {
        return this._attributes['emprDntlBenRptCd'];
    }
    set emprDntlBenRptCd(val: string) {
        this._attributes['emprDntlBenRptCd'] = val;
    }

    set emptIncamt(val: number) {
        this._attributes['emptIncamt'] = val;
    }
    set cppCntrbAmt(val: number) {
        this._attributes['cppCntrbAmt'] = val;
    }
    set qppCntrbAmt(val: number) {
        this._attributes['qppCntrbAmt'] = val;
    }
    set empeEipAmt(val: number) {
        this._attributes['empeEipAmt'] = val;
    }
    set itxDdctAmt(val: number) {
        this._attributes['itxDdctAmt'] = val;
    }
    set eiInsuErnAmt(val: number) {
        this._attributes['eiInsuErnAmt'] = val;
    }
    set cppQppErnAmt(val: number) {
        this._attributes['cppQppErnAmt'] = val;
    }
    set hmBrdLodgAmt(val: number) {
        this._attributes['hmBrdLodgAmt'] = val;
    }
    set spclWrkSiteAmt(val: number) {
        this._attributes['spclWrkSiteAmt'] = val;
    }
    set prscbZnTrvlAmt(val: number) {
        this._attributes['prscbZnTrvlAmt'] = val;
    }
    set medTrvlAmt(val: number) {
        this._attributes['medTrvlAmt'] = val;
    }
    set prsnlVhclAmt(val: number) {
        this._attributes['prsnlVhclAmt'] = val;
    }
    set lowIntLoanAmt(val: number) {
        this._attributes['lowIntLoanAmt'] = val;
    }
    set sobA00FebAmt(val: number) {
        this._attributes['sobA00FebAmt'] = val;
    }
    set sodDA00FebAmt(val: number) {
        this._attributes['sodDA00FebAmt'] = val;
    }
    set othTxBenAmt(val: number) {
        this._attributes['othTxBenAmt'] = val;
    }
    set sodD1A00FebAmt(val: number) {
        this._attributes['sodD1A00FebAmt'] = val;
    }
    set emptCmsnAmt(val: number) {
        this._attributes['emptCmsnAmt'] = val;
    }
    set cfppaAmt(val: number) {
        this._attributes['cfppaAmt'] = val;
    }
    set elgRtirAmt(val: number) {
        this._attributes['elgRtirAmt'] = val;
    }
    set nelgRtirAmt(val: number) {
        this._attributes['nelgRtirAmt'] = val;
    }
    set indnNelgRtirAmt(val: number) {
        this._attributes['indnNelgRtirAmt'] = val;
    }
    set indnEmpeAmt(val: number) {
        this._attributes['indnEmpeAmt'] = val;
    }
    set pr90CntrbrAmt(val: number) {
        this._attributes['pr90CntrbrAmt'] = val;
    }
    set pr90NcntrbrAmt(val: number) {
        this._attributes['pr90NcntrbrAmt'] = val;
    }
    set cmpnRpayEmprAmt(val: number) {
        this._attributes['cmpnRpayEmprAmt'] = val;
    }
    set fishGroErnAmt(val: number) {
        this._attributes['fishGroErnAmt'] = val;
    }
    set fishNetPtnrAmt(val: number) {
        this._attributes['fishNetPtnrAmt'] = val;
    }
    set fishShrPrsnAmt(val: number) {
        this._attributes['fishShrPrsnAmt'] = val;
    }
    set plcmtEmpAgcyAmt(val: number) {
        this._attributes['plcmtEmpAgcyAmt'] = val;
    }
    set drvrTaxisOthAmt(val: number) {
        this._attributes['drvrTaxisOthAmt'] = val;
    }
    set brbrHrdrssrAmt(val: number) {
        this._attributes['brbrHrdrssrAmt'] = val;
    }
    set epaidHlthPlnAmt(val: number) {
        this._attributes['epaidHlthPlnAmt'] = val;
    }
    set stokOptCshOutEamt(val: number) {
        this._attributes['stokOptCshOutEamt'] = val;
    }
    set vlntrEmergencyworkerXmptAmt(val: number) {
        this._attributes['vlntrEmergencyworkerXmptAmt'] = val;
    }
    set indnTxmptSeiAmt(val: number) {
        this._attributes['indnTxmptSeiAmt'] = val;
    }
    set emptIncAmtCovidPrd1(val: number) {
        this._attributes['emptIncAmtCovidPrd1'] = val;
    }
    set emptIncAmtCovidPrd2(val: number) {
        this._attributes['emptIncAmtCovidPrd2'] = val;
    }
    set emptIncAmtCovidPrd3(val: number) {
        this._attributes['emptIncAmtCovidPrd3'] = val;
    }
    set emptIncAmtCovidPrd4(val: number) {
        this._attributes['emptIncAmtCovidPrd4'] = val;
    }
    set rppCntrbAmt(val: number) {
        this._attributes['rppCntrbAmt'] = val;
    }
    set padjAmt(val: number) {
        this._attributes['padjAmt'] = val;
    }
    set provPipAmt(val: number) {
        this._attributes['provPipAmt'] = val;
    }
    set provInsuErnAmt(val: number) {
        this._attributes['provInsuErnAmt'] = val;
    }
    set unnDuesAmt(val: number) {
        this._attributes['unnDuesAmt'] = val;
    }
    set chrtyDonsAmt(val: number) {
        this._attributes['chrtyDonsAmt'] = val;
    }
    set cppQppXmptCd(val: number) {
        this._attributes['cppQppXmptCd'] = val;
    }
    set rppDpspRgstNbr(val: string) {
        this._attributes['rppDpspRgstNbr'] = val;
    }

    get emptProvCd(): string {
        return this._attributes['emptProvCd'];
    }

    get startDate(): Date | undefined {
        const date = parse(this._attributes['startDate']);
        if (isNaN(date.getTime())) return undefined;
        return date;
    }

    get endDate(): Date | undefined {
        const date = parse(this._attributes['endDate']);
        if (isNaN(date.getTime())) return undefined;
        return date;
    }

    get errors(): Record<string, string> {
        return this._attributes['errors'] || {};
    }

    stateBadge(): BadgeOptions {
        const label = `payroll.taxReturns.t4SummaryStatuses.${this.state}`;

        switch (this.state) {
            case 'open':
            case 'edited':
                return { label, type: 'informative' };
            case 'error':
                return { label, type: 'danger' };
            case 'processing':
            case 'complete':
                return { label, type: 'success' };
        }
    }
}
