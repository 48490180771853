import { T4Summary } from '@app/models/payroll/t4-summary.model';
import { TaxReturn } from '@app/models/payroll/tax-return.model';
import { BadgeColumn } from '@app/modules/table/classes/badge-column.class';
import { Column } from '@app/modules/table/classes/column.class';
import { CellDisplay } from '@app/modules/table/enums/cell-display.enum';
import { BadgeDisplay } from '@app/modules/table/interfaces/badge-display.interface';
import { TranslateService } from '@ngx-translate/core';

export class TitleColumn extends Column {
    type = CellDisplay.string;
    title = 'table.taxReturns.taxReturn';

    constructor(
        private year: number,
        private translateService: TranslateService
    ) {
        super();
    }

    getDisplayPropertyValue(object: TaxReturn): string {
        return this.translateService.instant(
            object.isAmendment ? 'payroll.taxReturns.annualReturnAmended' : 'payroll.taxReturns.annualReturn',
            { year: this.year }
        );
    }
}

export class GeneratedOnColumn extends Column {
    type = CellDisplay.date;
    property = 'createdAt';
    title = 'table.taxReturns.generatedOn';
}

export class StatusColumn extends BadgeColumn<T4Summary> {
    type = CellDisplay.badge;
    title = 'table.taxReturns.status';

    getBadgeDisplay(row: T4Summary): BadgeDisplay | undefined {
        if (row.createdAt === undefined) {
            return { label: 'payroll.taxReturns.t4SummaryStatuses.notGenerated', type: 'default' };
        }

        return row.stateBadge();
    }
}
