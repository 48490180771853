<mat-button-toggle-group class="split-button-group {{ type }}">
    <mat-button-toggle
        class="split-button-main"
        [attr.disabled]="disabled || loading ? true : null"
        [attr.tabindex]="disabled ? -1 : tabIndex"
        [attr.aria-label]="ariaLabel"
    >
        <ng-content></ng-content>
    </mat-button-toggle>
    <mat-button-toggle
        class="split-button-dropdown"
        [matMenuTriggerFor]="splitButtonMenu"
        [attr.disabled]="disabled || loading ? true : null"
        (click)="$event.stopPropagation()"
        aria-label="expand"
    >
        <mat-icon svgIcon="chevronDown"></mat-icon>
    </mat-button-toggle>
</mat-button-toggle-group>
