<form #form="ngForm">
    <div class="balance-container" *ngIf="!timeOffPolicy?.isFuture">
        <div class="policy-balance">
            <h4>{{ 'components.time-off-type-details.balanceToday' | translate }}</h4>
            <h2 *ngIf="!timeOffTypeAndPolicyWithBalance?.timeOffPolicy.isUnlimited; else infinity">
                {{ todaysBalance | number: decimalPlacesForPipe }}
                {{ timeOffPolicy?.timeOffType.unitOfTimePluralTranslation | appTranslate }}
            </h2>
        </div>
        <div class="policy-balance">
            <h4>{{ 'components.time-off-type-details.futureTimeApproved' | translate }}</h4>
            <h2>
                {{ (futureTimeOffRequestBalance ? futureTimeOffRequestBalance : 0) | number: decimalPlacesForPipe }}
                {{ timeOffPolicy?.timeOffType.unitOfTimePluralTranslation | appTranslate }}
            </h2>
            <h4>
                {{
                    {
                        key: 'components.time-off-type-details.futureTimePendingApproval',
                        params: {
                            futurePendingApproval: futurePendingTimeOffRequestBalance,
                            unit: timeOffPolicy?.timeOffType.unitOfTimePluralTranslation | appTranslate,
                        },
                    } | appTranslate
                }}
            </h4>
        </div>
        <div class="policy-balance">
            <h4>
                {{ { key: 'components.time-off-type-details.timeTakenInYear', params: { year: year } } | appTranslate }}
            </h4>
            <h2>
                {{
                    (currentYearTimeOffTransactionBalance ? currentYearTimeOffTransactionBalance : 0)
                        | number: decimalPlacesForPipe
                }}
                {{ timeOffPolicy?.timeOffType.unitOfTimePluralTranslation | appTranslate }}
            </h2>
        </div>
        <div *ngIf="displayExpiringTime" class="policy-balance">
            <h4>
                {{
                    {
                        key: 'components.time-off-type-details.timeExpiringOnDate',
                        params: { timeExpirationDate: carryoverBalanceExpiresOn },
                    } | appTranslate
                }}
            </h4>
            <h2>
                {{ currentCarryoverBalance | number: decimalPlacesForPipe }}
                {{ timeOffPolicy?.timeOffType.unitOfTimePluralTranslation | appTranslate }}
            </h2>
        </div>
    </div>

    <div class="text-align-left ui aligned divided list" style="margin-top: 5%">
        <div *ngIf="auth.isAdmin()" class="item">
            <div class="content app-grid">
                <p class="row-title columns-6">{{ 'components.time-off-type-details.policyName' | translate }}</p>
                <p class="columns-6">{{ timeOffPolicy?.name | titlecase }}</p>
            </div>
        </div>
        <div *ngIf="timeOffPolicy?.accrualFrequency" class="item">
            <div class="content app-grid">
                <p class="row-title columns-6">{{ 'components.time-off-type-details.accrualsOccur' | translate }}</p>
                <p class="columns-6">{{ timeOffPolicy.accrualFrequency | titlecase }}</p>
            </div>
        </div>
        <div class="item">
            <div class="content app-grid">
                <p class="row-title columns-6">{{ 'components.time-off-type-details.accrue' | translate }}</p>
                <p class="columns-6">{{ getAccrualMessage(timeOffPolicy) | appTranslate }}</p>
            </div>
        </div>
        <div *ngIf="timeOffPolicy?.carryoverCap !== null" class="item">
            <div class="content app-grid">
                <p class="row-title columns-6">{{ 'components.time-off-type-details.carryover' | translate }}</p>
                <p class="columns-6">
                    {{
                        {
                            key: 'components.time-off-type-details.carryOverAtEnd',
                            params: {
                                carryOverCap: timeOffPolicy?.carryoverCap | number: decimalPlacesForPipe,
                                unit: timeOffPolicy?.timeOffType.unitOfTimeSingularTranslation | appTranslate,
                                balanceDateLabel: timeOffTypeAndPolicyWithBalance.timeOffBalance.balanceDateLabel,
                            },
                        } | appTranslate
                    }}
                </p>
            </div>
        </div>
        <div *ngIf="timeOffPolicy?.carryoverExpireAfter" class="item">
            <div class="content app-grid">
                <p class="row-title columns-6">
                    {{ 'components.time-off-type-details.carryoverExpires' | translate }}
                </p>
                <p class="columns-6">
                    {{
                        {
                            key: 'components.time-off-type-details.carryoverExpiresAfterDays',
                            params: {
                                expireAfter: timeOffPolicy?.carryoverExpireAfter,
                                carryoverExpiresAfterDate: carryoverBalanceExpiresOn ?? '-',
                            },
                        } | appTranslate
                    }}
                </p>
            </div>
        </div>
        <div *ngIf="timeOffApprovalSteps.length > 0" class="item">
            <div class="content app-grid">
                <p class="row-title columns-6">{{ 'components.time-off-type-details.approvalFlow' | translate }}</p>
                <p class="columns-6">
                    {{
                        {
                            key: 'components.time-off-type-details.lengthStageApprovalFlow',
                            params: { expireAfter: timeOffApprovalSteps.length },
                        } | appTranslate
                    }}
                </p>
            </div>
        </div>
        <div *ngIf="timeOffPolicy?.timeOffRequestRule?.negativeBalanceCap" class="item">
            <div class="content app-grid">
                <p class="row-title columns-6">
                    {{ 'components.time-off-type-details.negativeBalance' | translate }}
                </p>
                <p class="columns-6">
                    {{
                        timeOffPolicy.timeOffRequestRule.negativeBalanceCap === 0
                            ? ('components.time-off-type-details.notAllowedCap' | translate)
                            : ('components.time-off-type-details.allowedCap'
                              | translate
                                  : {
                                        negativeBalanceCap: timeOffPolicy.timeOffRequestRule.negativeBalanceCap,
                                        unitOfTime:
                                            (timeOffPolicy?.timeOffType.unitOfTimePluralTranslation | translate),
                                    })
                    }}
                </p>
            </div>
        </div>
        <div *ngIf="timeOffPolicy?.timeOffRequestRule?.waitingPeriod" class="item">
            <div class="content app-grid">
                <p class="row-title columns-6">{{ 'components.time-off-type-details.waitingPeriod' | translate }}</p>
                <p class="columns-6">
                    {{
                        {
                            key: 'components.time-off-type-details.waitingPeriodDays',
                            params: {
                                waitingPeriod:
                                    timeOffPolicy.timeOffRequestRule?.waitingPeriod | number: decimalPlacesForPipe,
                            },
                        } | appTranslate
                    }}
                </p>
            </div>
        </div>
        <div *ngIf="timeOffPolicy?.timeOffRequestRule?.minDayAmount" class="item">
            <div class="content app-grid">
                <p class="row-title columns-6">
                    {{ 'components.time-off-type-details.shortestRequestAllowed' | translate }}
                </p>
                <p class="columns-6">
                    {{ timeOffPolicy.timeOffRequestRule?.minDayAmount | number: decimalPlacesForPipe }}
                    {{ timeOffPolicy?.timeOffType.unitOfTimePluralTranslation | appTranslate }}
                </p>
            </div>
        </div>
        <div *ngIf="timeOffPolicy?.timeOffRequestRule?.maxLength" class="item">
            <div class="content app-grid">
                <p class="row-title columns-6">
                    {{ 'components.time-off-type-details.longestRequestAllowed' | translate }}
                </p>
                <p class="columns-6">
                    {{ timeOffPolicy.timeOffRequestRule?.maxLength | number: decimalPlacesForPipe }}
                    {{ timeOffPolicy?.timeOffType.unitOfTimePluralTranslation | appTranslate }}
                </p>
            </div>
        </div>
        <mat-expansion-panel *ngIf="timeOffPolicy?.timeOffSeniorityOverrides.length" #matExpansionPanel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <h4>{{ 'components.time-off-type-details.seniorityIncrease' | translate }}</h4>
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="seniority-content flex-wrap-container margin-top-3 border-bottom">
                <p>{{ 'components.time-off-type-details.baseAccrual' | translate }}</p>
                <p>{{ getBaseAccrual(timeOffPolicy) | appTranslate }}</p>
            </div>
            <div *ngFor="let seniorityOverride of getSortedTimeOffSeniorityOverrides(); let last = last">
                <div class="seniority-content flex-wrap-container margin-top-3" [class.border-bottom]="!last">
                    <p>
                        {{
                            {
                                key: 'components.time-off-type-details.afterNumberYears',
                                params: { numberOfYears: seniorityOverride.afterNumberOfYears },
                            } | appTranslate
                        }}
                    </p>
                    <p>
                        {{
                            {
                                key: 'components.time-off-type-details.accruePeriodUnitYear',
                                params: {
                                    accruePeriod:
                                        getAccruesPerPeriod(seniorityOverride, timeOffPolicy)
                                        | number: decimalPlacesForPipe,
                                    unit: timeOffPolicy?.timeOffType.unitOfTimePluralTranslation | appTranslate,
                                },
                            } | appTranslate
                        }}
                    </p>
                    <ui-badge *ngIf="seniorityOverride.active" class="badge-alignment-left" type="primary">{{
                        'components.time-off-type-details.current' | translate
                    }}</ui-badge>
                </div>
            </div>
        </mat-expansion-panel>
    </div>
</form>

<ng-template #infinity>
    <h2 class="no-margin">
        <mat-icon svgIcon="infinity"></mat-icon>
    </h2>
</ng-template>
