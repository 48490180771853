import { Component, ElementRef, HostBinding, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { breakPoints, shadows, spacing } from '@app/styles/theme';
import { css } from 'emotion';

export type CardVariant = 'light' | 'dark' | 'outline';

const light = css`
    background-color: var(--card-background-color);
    box-shadow: ${shadows.card};
`;

const dark = css`
    background-color: var(--grey-200);
`;

const outline = css`
    background-color: var(--card-background-color);
    border: 1px solid var(--grey-400);
`;

const styles: Record<CardVariant, string> = {
    light,
    dark,
    outline,
};

const baseStyles = css`
    display: block;
    border-radius: 10px;
`;

const centeredStyle = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
`;

const clickableStyle = css`
    cursor: pointer;
    transition: 0.2s;
    &:hover {
        background-color: var(--grey-100);
    }
`;

const withMargin = css`
    margin-bottom: var(--card-bottom-margin, ${spacing(3)});
`;

const withSpacing = css`
    padding: var(--card-padding, ${spacing(4)});
    @media (max-width: ${breakPoints.lg}) {
        padding: var(--card-padding, ${spacing(3)});
    }
`;

const disabledStyle = css`
    opacity: 0.45;
    pointer-events: none;
`;

const loadingClass = 'loading';

/**
 * CardComponent is a generic card to be used whenever the design requires the content to be contained
 * within a card.
 */
@Component({
    selector: 'ui-card',
    template: ` <ng-content></ng-content> `,
    styleUrls: ['card.style.scss'],
})
export class CardComponent implements OnInit, OnChanges {
    @Input() loading = false;
    @Input() withSpacing = true;

    @Input() variant: CardVariant = 'light';
    @Input() centered = false;
    @Input() clickable = false;
    @Input() withMargin = true;
    @Input() disabled = false;

    private classList = this.el.nativeElement.classList;

    constructor(private el: ElementRef) {
        this.classList.add(baseStyles);
    }

    @HostBinding('tabIndex') get tabIndex(): number | undefined {
        return this.clickable && !this.disabled ? 0 : -1;
    }

    ngOnInit(): void {
        this.classList.add(styles[this.variant]);
        if (this.centered) {
            this.classList.add(centeredStyle);
        }
        if (this.disabled) {
            this.classList.add(disabledStyle);
        } else if (this.clickable) {
            this.classList.add(clickableStyle);
        }
        if (this.withMargin) {
            this.classList.add(withMargin);
        }
        if (this.withSpacing) {
            this.classList.add(withSpacing);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ('loading' in changes) {
            if (this.loading) {
                this.classList.add(loadingClass);
            } else {
                this.classList.remove(loadingClass);
            }
        }

        if ('variant' in changes) {
            const variantChange = changes.variant;
            this.classList.remove(styles[variantChange.previousValue]);
            this.classList.add(styles[variantChange.currentValue]);
        }
    }
}
