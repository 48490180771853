import { SigningTemplateSlotNames } from '@app/modules/documents-v2/enums/signing-template-slot-names.enum';
import { File } from '@models/common/file.model';
import { Model } from '../core/base.model';
import { Employee } from '../employee/employee.model';
import { SigningTemplate } from './signing-template.model';

export class SignatureRequest extends Model {
    protected static _type = 'signatureRequests';
    protected static _resource = 'signingTemplates/:signingTemplate/signatureRequests';
    protected static _version = 'v2';
    protected static _serializeAttributes = ['employeeId', 'signatureId', 'requestId', 'completedAt'];
    protected static _datetimes = ['createdAt', 'requestedAt', 'completedAt'];

    get name(): SigningTemplateSlotNames {
        return this._attributes['name'];
    }

    get file(): File {
        return this.hasOne(File, 'file');
    }

    get signingTemplateId(): number {
        return this._attributes['signingTemplateId'];
    }

    get signingTemplate(): SigningTemplate {
        return this.hasOne(SigningTemplate, 'signingTemplate');
    }

    get bulkJobId(): string {
        return this._attributes['bulkJobId'];
    }

    set bulkJobId(val: string) {
        this._attributes['bulkJobId'] = val;
    }

    get signatureId(): string {
        return this._attributes['signatureId'];
    }

    set signatureId(val: string) {
        this._attributes['signatureId'] = val;
    }

    get requestId(): string {
        return this._attributes['requestId'];
    }

    set requestId(val: string) {
        this._attributes['requestId'] = val;
    }

    /**
     * fileId will only be present once the signing process is complete.
     * It points to a PDF copy of the completed/signed template.
     */
    get fileId(): number {
        return this._attributes['fileId'];
    }

    get signingUrl(): string | null {
        return this._attributes['signingUrl'];
    }

    get isOnboarding(): boolean {
        return this._attributes['isOnboarding'];
    }

    set isOnboarding(val: boolean) {
        this._attributes['isOnboarding'] = val;
    }

    get requestedAt(): Date {
        return this._attributes['requestedAt'];
    }

    get completedAt(): Date {
        return this._attributes['completedAt'];
    }

    set completedAt(val: Date) {
        this._attributes['completedAt'] = val;
    }

    get employee(): Employee {
        return this.hasOne(Employee, 'employee');
    }

    /**
     * Cosigners are signers who do not have the Employee signing role
     * Cosigners are _not_ all signers.
     */
    get cosigners(): SignatureRequest[] {
        return this.hasMany(SignatureRequest, 'cosigners');
    }

    get order(): number {
        return this._attributes['order'];
    }

    /**
     * isMultiSigner only works if cosigners are loaded
     */
    isMultiSigner(): boolean {
        return this.cosigners.length > 0;
    }

    isSigned(): boolean {
        return Boolean(this.completedAt);
    }

    /**
     * isComplete only works for multi-signer documents if cosigners are loaded
     */
    isComplete(): boolean {
        if (this.isMultiSigner()) {
            const unsignedByCosigner = this.cosigners.some(
                (cosignerRequest: SignatureRequest) => !cosignerRequest.isSigned()
            );
            return !unsignedByCosigner && this.isSigned();
        }

        return this.isSigned();
    }

    isProcessing(): boolean {
        return this.fileId === null && this.isComplete();
    }

    isCreating(): boolean {
        return this.bulkJobId && !this.requestId && !this.signatureId;
    }
}
