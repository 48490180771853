import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenu, MatLegacyMenuModule } from '@angular/material/legacy-menu';
import { ButtonTypes } from '../button/button.component';

@Component({
    selector: 'ui-split-button',
    templateUrl: './split-button.template.html',
    standalone: true,
    imports: [MatButtonToggleModule, MatIconModule, NgClass, MatLegacyMenuModule],
    styleUrls: ['./split-button.style.scss'],
})
export class SplitButtonComponent {
    @Input() type: ButtonTypes = 'ghost';
    @Input() disabled = false;
    @Input() loading = false;
    @Input() tabIndex = '';
    @Input('aria-label') ariaLabel: string | null = null;
    @Input() splitButtonMenu: MatLegacyMenu | null = null;
}
