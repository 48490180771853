import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, ElementRef, Input, OnDestroy } from '@angular/core';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenu, MatLegacyMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { RouterModule } from '@angular/router';

export type ButtonTypes = 'primary' | 'ghost' | 'link' | 'outline' | 'danger' | 'danger-ghost' | 'icon' | 'filter';
export type ButtonSizes = null | 'compact' | 'no-padding' | 'mobile-shrink' | 'full-width' | 'stripped' | 'min-padding';

@Component({
    selector: 'ui-button',
    templateUrl: './button.template.html',
    standalone: true,
    imports: [
        MatButtonToggleModule,
        MatIconModule,
        RouterModule,
        NgClass,
        NgTemplateOutlet,
        MatLegacyMenuModule,
        MatLegacyProgressSpinnerModule,
    ],
    styleUrls: ['./button.style.scss'],
})
export class ButtonComponent implements OnDestroy {
    @Input()
    set submit(val: boolean) {
        this._submit = coerceBooleanProperty(val);
    }

    get submit(): boolean {
        return this._submit;
    }

    /**
     * button display type
     */
    @Input() type: ButtonTypes = 'ghost';

    @Input() size: ButtonSizes = null;
    @Input() url = '';
    @Input() queryParams: { [key: string]: primitive } = {};
    /**
     * Link is off app, does not utilize the ng-router
     */
    @Input() externalLink = false;
    // The default behaviour for openInNewTab varies depending on whether it is an external link or internal link
    // WHEN: externalLink=true, openInNewTab=undefined will default to "true" since external links should default to a new tab
    // WHEN: externalLink=false, openInNewTab=undefined will default to "false" since internal links should default to the same tab
    // This behaviour can also be overwritten by explicitly setting the openInNewTab property to "true" or "false"
    @Input() openInNewTab?: boolean = undefined;
    @Input() tabIndexValue = '';
    @Input() loading = false;
    @Input() matMenu: MatLegacyMenu | null = null;
    @Input('aria-label') ariaLabel: string | null = null;

    @Input() get disabled(): boolean {
        return this._disabled;
    }

    set disabled(value: boolean) {
        this._disabled = coerceBooleanProperty(value);
    }

    private _disabled = false;
    private _submit = false;

    constructor(private elementRef: ElementRef) {
        this.elementRef.nativeElement.addEventListener('click', this.stopDisabledEventPropagation.bind(this));
    }

    ngOnDestroy(): void {
        this.elementRef.nativeElement.removeEventListener('click', this.stopDisabledEventPropagation.bind(this));
    }

    get buttonClass(): string {
        const classes: string[] = [this.type];

        if (this.size) {
            classes.push(this.size);
        }

        return classes.join(' ');
    }

    /**
     * Used to stop click events added to the host element when a button is disabled
     */
    private stopDisabledEventPropagation(event: Event): void {
        if (this.disabled) {
            event.stopImmediatePropagation();
        }
    }
}
