import { Component, Input } from '@angular/core';
import { TimeOffType } from '@models/time-off-v3/time-off-type.model';
import {
    DECIMAL_PLACES_FOR_PIPE,
    TimeOffDateFormatDashes,
    TimeOffDateFormatShortMonthDayYear,
} from '@time-off-v3/meta/time-off-meta';
import { TimeOffService } from '@time-off-v3/services/time-off.service';
import moment, { Moment } from 'moment';
import { BaseForm } from '@forms/base.form';
import { MatCalendarCellClassFunction } from '@angular/material/datepicker';

@Component({
    selector: 'app-calculate-balance-form',
    templateUrl: 'calculate-balance.template.html',
    styleUrls: ['calculate-balance.style.scss'],
})
export class CalculateBalanceForm extends BaseForm {
    @Input() employeeId: number;
    @Input() timeOffTypes: TimeOffType[];
    selectedDate: Moment = moment();
    balance = 0;
    futureRequestsAmountApproved = 0;
    futureRequestsAmountPending = 0;
    expiringBalance = 0;
    carryoverBalanceExpiresOn: string | null;
    timeOffTypeId: number;
    carryoverCap: number;
    isLoading = false;
    showCalendar = false;
    currentDateBeforeExpiryDate = false;
    timeOffType: TimeOffType;
    decimalPlacesForPipe = DECIMAL_PLACES_FOR_PIPE;

    dateClass: MatCalendarCellClassFunction<Date> = (cellDate, view) => {
        if (view === 'month' && this.carryoverBalanceExpiresOn) {
            return moment(cellDate).format(TimeOffDateFormatShortMonthDayYear) === this.carryoverBalanceExpiresOn
                ? 'carryover-date-highlight'
                : '';
        }

        return '';
    };

    constructor(private timeOffService: TimeOffService) {
        super();
    }

    async getBalanceOnDateSelect(): Promise<void> {
        this.expiringBalance = 0;
        this.carryoverBalanceExpiresOn = null;
        this.currentDateBeforeExpiryDate = false;

        this.isLoading = true;

        this.timeOffType = this.timeOffTypes.find((timeOffType) => timeOffType.id === this.timeOffTypeId);

        const getBalance = await this.timeOffService.getBalance(this.employeeId, {
            'filters[timeOffTypeId]': this.timeOffTypeId.toString(),
            'filters[processedAt][to]': this.selectedDate.format(TimeOffDateFormatDashes),
        });

        const isHourly = this.timeOffType.displayInHours;

        this.balance = isHourly ? getBalance.balanceHours : getBalance.balance;
        this.futureRequestsAmountApproved = isHourly ? getBalance.futureHoursApproved : getBalance.futureDaysApproved;
        this.futureRequestsAmountPending = isHourly ? getBalance.futureHoursPending : getBalance.futureDaysPending;

        if (this.carryoverCap) {
            const expiringBalance = await this.timeOffService.getExpiringBalance(this.employeeId, {
                'filters[timeOffTypeId]': this.timeOffTypeId.toString(),
                'filters[asOfDate]': moment().format(TimeOffDateFormatDashes),
            });

            this.expiringBalance = isHourly ? expiringBalance.expiringHoursBalance : expiringBalance.expiringBalance;

            if (expiringBalance.expiresInDays) {
                this.carryoverBalanceExpiresOn = moment()
                    .subtract(1, 'days') // carryover balance expires day before.
                    .add(expiringBalance.expiresInDays, 'days')
                    .format(TimeOffDateFormatShortMonthDayYear);
            }

            this.currentDateBeforeExpiryDate = moment().isBefore(moment(this.carryoverBalanceExpiresOn));
        }

        this.showCalendar = true;
        this.isLoading = false;
    }
}
