<ui-card class="h-100" [withMargin]="false" [withSpacing]="false">
    <div class="policy-name">
        <mat-icon [svgIcon]="icon" [ngStyle]="{ color: color }"></mat-icon>
        {{ timeOffTypeName | appTranslate }}
    </div>
    <app-context-menu [withBorder]="false">
        <ng-content></ng-content>
    </app-context-menu>
    <div class="accrual-amount">
        <h1 class="no-margin" *ngIf="!isUnlimited; else infinity">{{ amount | number: decimalPlacesForPipe }}</h1>
        <h4 class="no-margin">
            {{ unitOfTime | appTranslate | titlecase }} {{ 'components.time-off-policy-card.balance' | translate }}
            {{ balanceDateSetting | appTranslateOptions: dateDisplayOptions }} {{ balanceDateLabel }}
        </h4>
    </div>
    <ng-container>
        <p *ngIf="timeOffPolicy?.isFuture && !timeOffPolicy?.isAMultiPolicy" class="future-policy-text">
            {{
                {
                    key: 'components.time-off-type-details.policyTakesEffectOn',
                    params: { date: timeOffPolicy?.effectiveAt | appDate: 'mediumDate' },
                } | appTranslate
            }}
        </p>

        <p
            *ngIf="timeOffPolicy?.nextFuturePolicy && timeOffPolicy?.isAMultiPolicy && isAdmin"
            class="future-policy-text"
        >
            {{
                {
                    key: 'components.time-off-type-details.policyWillBeChangedToNameOn',
                    params: {
                        name: timeOffPolicy?.nextFuturePolicy.name,
                        date: timeOffPolicy?.nextFuturePolicy.effectiveAt | appDate: 'mediumDate',
                    },
                } | appTranslate
            }}
        </p>
    </ng-container>
    <ng-content select=".view-details-button"></ng-content>
</ui-card>

<ng-template #infinity>
    <h1 class="no-margin">
        <mat-icon svgIcon="infinity"></mat-icon>
    </h1>
</ng-template>
