import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { SecurePipe } from '@app/pipes/secure.pipe';
import { FileHelperService, NotifyService, UrlHelperService } from '@app/services';
import { File } from '@models/common/file.model';
import { Subscription } from 'rxjs';
import { PdfViewerComponent } from '../pdf-viewer/pdf-viewer.component';

/*
 * This component can currently be used to view PDFs and Images (jpeg, png, gifs)
 * instead of directly relying on the a specific file type viewer eg. pdf-viewer
 */
@Component({
    selector: 'app-file-viewer',
    templateUrl: './file-viewer.template.html',
    styleUrls: ['./file-viewer.style.scss'],
})
export class FileViewerComponent implements AfterViewInit, OnDestroy {
    @ViewChild('pdfViewer') pdfViewer: PdfViewerComponent;
    @Input() file: File | null = null;
    @Input() maxHeight: number | null = null;

    documentImageFilePath = '';
    securePipe: SecurePipe;
    private documentImageTransformSubscription: Subscription = null;

    constructor(
        private fileHelper: FileHelperService,
        private ref: ChangeDetectorRef,
        private urlHelperService: UrlHelperService,
        private sanitizer: DomSanitizer,
        protected notify: NotifyService
    ) {
        this.securePipe = new SecurePipe(this.ref, this.urlHelperService, this.sanitizer);
    }

    /*
     * If there is a file given to the component during rendering we will determine the
     * correct preview strategy and display the file for the user.
     */
    ngAfterViewInit(): void {
        if (!this.file) {
            return;
        }

        this.previewFile();
    }

    ngOnDestroy(): void {
        this.documentImageTransformSubscription?.unsubscribe();
    }

    previewFile(): void {
        switch (this.file.type.toLowerCase()) {
            case 'gif':
            case 'jpg':
            case 'jpeg':
            case 'png':
                this.previewImage();
                break;
            case 'pdf':
                this.previewPdf();
                break;

            default:
                this.notify.error('File type ' + this.file.type + ' is not supported by the file viewer');
                throw new Error('File type is not supported by the file viewer');
        }
    }

    previewImage(): void {
        this.transformImage();
    }

    previewPdf(): void {
        this.pdfViewer.setSource(this.fileHelper.path(this.file.id));
    }

    reset(): void {
        this.file = null;
        this.documentImageFilePath = '';
    }

    /*
     * We can't directly display images since we can't attach token headers to images we
     * request from <img> tags, therefore we need to transform and get the binary/blob to display it
     */
    private transformImage(): void {
        this.documentImageTransformSubscription = this.securePipe
            .internalTransform(this.fileHelper.path(this.file.id))
            .subscribe((value) => {
                this.documentImageFilePath = value;
            });
    }
}
