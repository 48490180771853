<p class="padding-bottom-3">
    {{ 'time-off-v3.calculateBalanceDescription' | translate: { timeOffTypeName: this.timeOffType?.name } }}
</p>
<form #form="ngForm" autocomplete="off" class="notranslate" [class.loading]="isLoading">
    <mat-calendar
        #calendar
        *ngIf="showCalendar"
        [dateClass]="dateClass"
        [(selected)]="selectedDate"
        (selectedChange)="getBalanceOnDateSelect()"
    ></mat-calendar>
    <div class="balance">
        <div class="balance-detail">
            <div>
                {{
                    {
                        key: 'components.calculate-balance.balanceOn',
                        params: { date: selectedDate | appDate: 'mediumDate' },
                    } | appTranslate
                }}
            </div>
            <h3 class="no-margin">{{ balance }} {{ this.timeOffType?.unitOfTimePluralTranslation | appTranslate }}</h3>
        </div>
        <div>
            <div>
                {{
                    {
                        key: 'components.calculate-balance.unitScheduledAfterDate',
                        params: { date: selectedDate | appDate: 'mediumDate' },
                    } | appTranslate
                }}
            </div>
            <h3 class="no-margin">
                {{
                    {
                        key: 'components.calculate-balance.amountApprovedOfUnitApproved',
                        params: {
                            amountApproved: futureRequestsAmountApproved | number: decimalPlacesForPipe,
                            unit: this.timeOffType?.unitOfTimePluralTranslation | appTranslate,
                        },
                    } | appTranslate
                }}
            </h3>
            <div *ngIf="!!futureRequestsAmountPending">
                ({{
                    {
                        key: 'components.calculate-balance.futureRequestOfUnitPending',
                        params: {
                            amountPending: futureRequestsAmountPending | number: decimalPlacesForPipe,
                            unit: this.timeOffType?.unitOfTimePluralTranslation | appTranslate,
                        },
                    } | appTranslate
                }})
            </div>
        </div>
    </div>
</form>
<div *ngIf="this.currentDateBeforeExpiryDate && this.expiringBalance > 0" class="flex align-items-center padding-top-3">
    <div class="bullet circle"></div>
    <p
        class="padding-left-1"
        [innerHTML]="
            'time-off-v3.carryoverExpirationDaysRemaining'
                | translate
                    : {
                          days: this.expiringBalance,
                          unit: this.timeOffType?.unitOfTimePlural,
                          carryoverExpirationDate: this.carryoverBalanceExpiresOn,
                      }
        "
    ></p>
</div>
