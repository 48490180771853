import { Injectable } from '@angular/core';
import { FeatureFlag } from '@app/enums';
import { MenuItem } from '@app/interfaces';
import { MenuService } from '@app/services/menu/menu.service';

const TimeOffModuleMenu: MenuItem[] = [
    {
        key: 'time-off-v3.requests',
        link: ['/time-off', 'v3', 'requests'],
    },
    {
        key: 'time-off-v3.typesAndPolicies',
        link: ['/time-off', 'v3', 'types-and-policies'],
        hideIfHasFeatureFlag: FeatureFlag.timeOffImprovements,
    },
    {
        key: 'time-off-v3.timeOffPolicies',
        link: ['/time-off', 'v3', 'types-and-policies'],
        showIfHasFeatureFlag: FeatureFlag.timeOffImprovements,
    },
    {
        key: 'time-off-v3.settings',
        link: ['/time-off', 'v3', 'settings'],
    },
];

const TimeOffManagerProfileMenu: MenuItem[] = [
    {
        key: 'time-off-v3.myTeam',
        link: ['/profile', 'time-off', 'v3', 'team'],
        routerLinkExact: true,
    },
    {
        key: 'time-off-v3.myProfile',
        link: ['/profile', 'time-off'],
        routerLinkExact: true,
    },
];

@Injectable()
export class TimeOffModuleMenuService extends MenuService {
    setBaseMenu(): void {
        this.baseMenu = TimeOffModuleMenu;
    }
}

@Injectable()
export class TimeOffProfileMenuService extends MenuService {
    setBaseMenu(): void {
        this.baseMenu = this.auth.canAny(['manageDirectReports', 'manageDirectAndIndirectReports'])
            ? TimeOffManagerProfileMenu
            : [];
    }
}
