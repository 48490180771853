import { Paginator } from '@app/classes';
import { AppDatePipe } from '@app/pipes';
import { AuthService } from '@app/services';
import { T4 } from '@models/payroll/t4.model';
import { TranslateService } from '@ngx-translate/core';
import { Table } from '@table/classes/table.class';
import { MenuColumnItem } from '@table/interfaces/menu-column-item.interface';
import {
    ApplicableDateRangeColumn,
    EmployeeStatusColumn,
    FormStatusColumn,
    FormTypeColumn,
    NameColumn,
    ProvinceOfEmploymentColumn,
} from './columns';

const PAGE_SIZE = 10;

export type Event = 'view' | 'download';

const menuOptions: MenuColumnItem<Event>[] = [
    { label: 'table.employeeTaxForms.viewDetails', event: 'view' },
    { label: 'table.employeeTaxForms.downloadPreview', event: 'download' },
];

export class EmployeeTaxFormsTable extends Table<T4> {
    showMenuColumnOptions = true;
    t4SummaryId: number;

    constructor(
        protected auth: AuthService,
        private datePipe: AppDatePipe,
        private translateService: TranslateService,
        t4SummaryId: number | undefined
    ) {
        super(auth);
        // This will ensure that we don't pick up T4s that have a t4SummaryId of null
        this.t4SummaryId = t4SummaryId || 0;
        this.boot();
    }

    setColumns(): void {
        this.columns = [
            new NameColumn(),
            new EmployeeStatusColumn(),
            new FormTypeColumn(),
            new ApplicableDateRangeColumn(this.datePipe),
            new ProvinceOfEmploymentColumn(this.translateService),
            new FormStatusColumn(),
        ];
    }

    setDataSource(): void {
        this.paginator = new Paginator(
            T4.param('company', this.auth.company.id).where('t4_summary_id', this.t4SummaryId).limit(PAGE_SIZE),
            {
                pageSize: PAGE_SIZE,
            }
        );
    }

    getMenuColumnOptionsForRow(): MenuColumnItem[] {
        return menuOptions;
    }
}
