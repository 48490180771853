import { BenefitShowMenuService, BenefitsMenuService } from '@benefits/services/benefits-menu.service';
import { BenefitPlanEmployeeAssignmentService } from './benefit-plan-employee-assignment.service';
import { BenefitsService } from './benefits.service';
import { SimplyBenefitsService } from './simply-benefits.service';

const Services = [
    BenefitPlanEmployeeAssignmentService,
    BenefitsService,
    SimplyBenefitsService,
    BenefitsMenuService,
    BenefitShowMenuService,
];

export {
    BenefitPlanEmployeeAssignmentService,
    BenefitShowMenuService,
    BenefitsMenuService,
    BenefitsService,
    Services,
    SimplyBenefitsService,
};
