import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA } from '@angular/material/legacy-snack-bar';
import { RouterLink } from '@angular/router';
import { getIconNames } from '@app/constants/icons';
import { TranslatableObject } from '@app/types/translatable.type';

const iconNames = getIconNames();

export type SnackBarAction = {
    onClick: (() => void) | RouterLink['routerLink'];
    text?: string;
};

export type SnackBarData = {
    icon?: (typeof iconNames)[number];
    action?: SnackBarAction;
} & Partial<TranslatableObject>;

/*
 * Do not use this component directly - only for use with MatSnackBar
 */
@Component({
    selector: 'app-snack-bar-message',
    templateUrl: './snack-bar-message.template.html',
    styleUrls: ['./snack-bar-message.style.scss'],
})
export class SnackBarMessageComponent {
    constructor(@Inject(MAT_SNACK_BAR_DATA) public data: SnackBarData) {}

    isFunction(onClick: SnackBarAction['onClick']): onClick is () => void {
        return typeof onClick === 'function';
    }
}
