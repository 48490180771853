import { contentChild, Directive, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { ModelUnion } from '@app/models/core/base.model';
import { QueryFetcher } from '@app/models/core/query-fetcher.model';
import { AuthService } from '@app/services/auth.service';
import { Translatable } from '@app/types/translatable.type';
import { PaginateFilters } from '@interfaces/paginate-filters.interface';
import { differenceBy } from 'lodash-es';
import { take } from 'rxjs/operators';
import { BaseDialogComponent } from '../base.dialog';
import { BulkSelectConfirmation } from '../bulk-select-confirmation/bulk-select-confirmation.component';
import { SelectableModel } from './selectable-model.interface';

@Directive()
export abstract class BulkSelectBaseDialogComponent<
    ShouldResolveGroupedModels extends boolean | undefined = undefined,
> extends BaseDialogComponent<ShouldResolveGroupedModels> {
    @Input() label = 'Select';
    @Input() headerText = 'Select Items';

    @Input() maxItemsToSelect = null;

    @Input() bannerType = 'info';
    @Input() displayBannerBasedOnMaxItems = false;
    @Input() alwaysDisplayBanner = false;
    @Input() bannerContent: Translatable;

    /**
     * tooltipTextMaker is a function that takes in a SelectableModel and returns
     * a string that will be used as the tooltip text for that item.
     */
    @Input() tooltipTextMaker: ((item: SelectableModel) => string | null) | null = null;

    /**
     * additionalQueryFilters is a way of modifying the query to the backend
     * before it is sent. Unlike "filters", these are not user-facing filters.
     * The user cannot choose to remove them.
     *
     * Ex: Ensuring you only get employees who are assigned to Time Tracking.
     */
    @Input() additionalQueryFilters: ((queryBuilder: QueryFetcher) => QueryFetcher)[] = [];

    /**
     * When true, hides the "Select Page" bulk action from modal
     */
    @Input() hideSelectPage = false;

    /**
     * Whether a confirmation screen should be shown to the user before closing the dialog
     */
    @Input() isConfirmationRequired = false;

    /**
     * Whether the dialog should resolve with a grouped array of models rather than the complete selection (ie. {removals: [], additions: []})
     */
    @Input() shouldResolveWithAdditionsAndRemovals?: ShouldResolveGroupedModels;
    @Output() filtersAdded: EventEmitter<null> = new EventEmitter<null>();

    isLoading = false;

    filters: any = {};
    filterValues: any = {};
    activeFilters: any = {};
    filtersToPaginate: PaginateFilters = {};
    items: SelectableModel[] = [];
    totalResults = 0;
    currentPage = 1;
    maxShowing = 25;
    selectItems: SelectableModel[] = [];
    allSelectedItemIds: number[] = [];
    allSelected = false;
    selection: any[] | null = null;
    queryString: string;
    allowRemoval = true;

    /**
     * Whether we're currently displaying the confirmation screen
     */
    protected isConfirmationScreenVisible = false;

    /**
     * An array of all the employees that are to be added to the existing collection
     */
    protected additions: ModelUnion[] = [];
    /**
     * An array of all the employees that are to be removed from the existing collection
     */
    protected removals: ModelUnion[] = [];
    protected existingSelection: SelectableModel[];

    /**
     * The BulkSelectConfirmation component. This is provided as ng-content to this component via composition.
     * If it's not provided, the confirmation screen will not be shown.
     */
    private confirmationScreen = contentChild(BulkSelectConfirmation);

    constructor(
        protected element: ElementRef,
        protected auth: AuthService
    ) {
        super(element);
    }

    onFilter(filters: any): void {
        this.activeFilters = filters;
        this.loadItems();
        this.filtersAdded.emit();
    }

    onSearch(query): void {
        if (query && query.length) {
            this.queryString = query;
        } else {
            this.queryString = null;
        }
        this.loadItems();
    }

    paginateFilter(key: string): void {
        const currentFilterToPaginate = this.filtersToPaginate[key];

        if (!currentFilterToPaginate) {
            return;
        }

        currentFilterToPaginate.paginateFunction();
    }

    /**
     * Reset the form
     */
    onBeforeShow(): void {
        this.items = [];
        this.selection = [];
        this.selectItems = [];
        this.allSelectedItemIds = [];
        this.activeFilters = {};
        this.queryString = null;
        this.allSelected = false;
        this.currentPage = 1;
        this.isLoading = true;
        this.loadItems();
        this.loadFilterOptions();
        this.loadExistingSelection();
    }

    loadExistingSelection(): void {
        if (this.existingSelection && this.existingSelection.length) {
            this.selectItems = this.existingSelection.slice(0);
            this.selection = this.selectItems;
        }
    }

    onPageChange(page: number): void {
        this.currentPage = page;
        this.loadItems();
    }

    select(item: SelectableModel): void {
        this.selectItems.unshift(item);
        this.updateModelSelection();
    }

    remove(item: SelectableModel): void {
        if (!this.allowRemoval) {
            return;
        }
        this.selectItems = this.selectItems.filter((selectItem) => selectItem.id !== item.id);
        this.loadItems();
        this.updateModelSelection();
    }

    selectAll(): void {
        let request = new QueryFetcher({});

        request = this.applyQuery(request);

        request = this.applyFilters(request);

        request
            .all()
            .then(([items]) => {
                this.selectItems = items;
                this.updateModelSelection();
            })
            .catch(() => {});
    }

    selectPage(): void {
        for (const item of this.items) {
            if (!this.isSelected(item)) {
                this.selectItems.push(item);
            }
        }
        this.updateModelSelection();
    }

    removeAll(): void {
        if (!this.allowRemoval) {
            return;
        }

        this.selectItems = [];
        this.allSelected = false;
        this.loadItems();
        this.updateModelSelection();
    }

    isSelected(item: SelectableModel): boolean {
        if (this.allSelected) {
            return true;
        }

        return (
            this.selectItems.filter((selectItem) => {
                if (!item || !selectItem) {
                    return false;
                }
                return +item.id === +selectItem.id;
            }).length !== 0
        );
    }

    approve(): void {
        const confirmationScreen = this.confirmationScreen();
        if (this.isConfirmationRequired && confirmationScreen) {
            this.removals = differenceBy(this.existingSelection as ModelUnion[], this.selection as ModelUnion[], 'id');
            this.additions = differenceBy(this.selection, this.existingSelection, 'id');
            if (!this.removals.length && !this.additions.length) {
                // No changes
                super.deny();
                return;
            }

            this.showConfirmationScreen(confirmationScreen);
        } else {
            // No confirmation screen
            super.approve();
        }
    }

    protected onApprove(): void {
        // Determine the shape of the promise to be returned
        if (this.shouldResolveWithAdditionsAndRemovals) {
            this.resolve?.({ additions: this.additions, removals: this.removals });
        } else {
            this.resolve?.(this.selection);
        }
    }

    protected onDeny(): void {
        this.isConfirmationScreenVisible = false;
        this.resolve?.(null);
    }

    protected loadFilterOptions(): void {}

    protected updateModelSelection(): void {
        this.selection = this.selectItems;
    }

    protected loadItems(): void {
        let request = new QueryFetcher({});

        request = this.applyQuery(request);
        request = this.applyFilters(request);
        request = this.applyAdditionalQueryFilters(request);

        request
            .get()
            .then(([items, meta]) => {
                this.totalResults = meta.pagination.total;
                this.currentPage = meta.pagination.page;
                this.items = items;
                this.isLoading = false;
            })
            .catch(() => {});
    }

    protected applyQuery(request: QueryFetcher): QueryFetcher {
        if (this.queryString && this.queryString.length) {
            request = request.where('query', this.queryString);
        }
        return request;
    }

    protected applyFilters(request: QueryFetcher): QueryFetcher {
        if (this.activeFilters && Object.keys(this.activeFilters).length) {
            for (const field in this.activeFilters) {
                request = request.where(field, this.activeFilters[field]);
            }
        }
        return request;
    }

    protected applyAdditionalQueryFilters(request: QueryFetcher): QueryFetcher {
        for (const filter of this.additionalQueryFilters) {
            request = filter(request);
        }

        return request;
    }

    /**
     * Sets the required variables for displaying the confirmation screen prior to resolving the promise with the selected data.
     * Also subscribes to the buttons on the confirmation screen.
     */
    private showConfirmationScreen(confirmationScreen: BulkSelectConfirmation): void {
        confirmationScreen.additions.set(this.additions);
        confirmationScreen.removals.set(this.removals);
        confirmationScreen.onActionPerformed.pipe(take(1)).subscribe((action) => {
            // If they click "cancel" it's the same as exiting out via ESC
            if (action === 'CANCEL') {
                super.deny();
                // If they click "confirm" it's the same as clicking "select" on the first screen without a confirmation screen
            } else if (action === 'CONFIRM') {
                super.approve();
            }
            // Regardless, if they click any buttons, we hide the confirmation screen.
            // This occurs if they click "back" as well, bringing them to the first screen
            this.isConfirmationScreenVisible = false;
        });

        this.isConfirmationScreenVisible = true;
    }
}
