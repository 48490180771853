import { SimpleEntityActions } from '@app/classes';

import { arrayChunk, inLocal } from '@app/functions';
import { DocumentType } from '@app/modules/documents-v2/enums/document-type.enum';
import { File } from '../common/file.model';
import { Model } from '../core/base.model';
import { Employee } from '../employee/employee.model';
import { DocumentAcknowledgement } from './document-acknowledgement.model';
import { DocumentTag } from './document-tag.model';
import { SignatureRequest } from './signature-request.model';
import { SigningTemplate } from './signing-template.model';

/**
 * @deprecated
 */
export type LegacyDocumentType = 'Offer Letter Template' | 'Signing Template' | 'View Only';

const SIGNING_DOCUMENT_TYPES = [DocumentType.standardSigningDocument, DocumentType.offerLetter];

/**
 * A Company Document is multifaceted and overloaded.
 *
 * A user can upload a Company Document to their company. This creates the Company Document entity which Has One File.
 *
 * When uploading a Company Document, the user must choose what type of document they are uploading: Offer Letter, Signing Template, or Document Acknowledgement.
 *
 * If (and only if) the user chooses Document Acknowledgement as the type of Company Document, and makes the Company Document "public", then employees can view the document under "Company Docs".
 *
 * If the user chooses Document Acknowledgement as the type of Company Document, the Company Document has the type of "acknowledgement" and the user can now assign Document Acknowledgements to employees. Assigning a Document Acknowledgement creates an Employee Document and a Document Acknowledgement. In this case, the Employee Document and the Company Document share the same file.
 *
 * If the user chooses Signing Template as the type of Company Document, the Company Document has the type of "standard_signing_document" and a Signing Template is created that belongs to the Company Document. The user must fill out the signing template. The user can now assign the signing template to employees, which creates a Signature Request which belongs to the Signing Template and who's source is the Employee. A Company Document of type "standard_signing_document" can never be made public.
 *
 * If the user chooses Offer Letter as the type of Company Document, the Company Document has the type of "offer_letter" and a Signing Template is created that belongs to the Company Document. The user must fill out the signing template. The user can now send the Offer Letter to Candidates, which creates a Candidate, an Offer Letter belonging to the Candidate, and a Signature Request which belongs to the Signing Template and who's source is the Candidate. A Company Document of type "offer_letter" can never be made public.
 *
 */
export class CompanyDocument extends Model {
    static SIGNING_TEMPLATE = 'Signing Template';
    static VIEW_ONLY = 'View Only';
    static permission = new SimpleEntityActions('companyDocument');

    protected static _resource = 'companyDocuments';
    protected static _version = 'v2';
    protected static _datetimes = ['createdAt', 'updatedAt'];
    protected static _serializeAttributes = ['name', 'fileId', 'isPublic', 'type', 'attach', 'detach'];
    protected static _type = 'companyDocuments';

    get verb(): 'sign' | 'acknowledge' {
        return this.isSigningDocument() ? 'sign' : 'acknowledge';
    }

    get name(): string | null {
        return this._attributes['name'] || null;
    }

    set name(val: string) {
        this._attributes['name'] = val;
    }

    get fileId(): number | null {
        return this._attributes['fileId'] || null;
    }

    set fileId(val: number) {
        this._attributes['fileId'] = val;
    }

    get isPrivate(): boolean {
        return !this.isPublic;
    }

    /**
     * Only documents of type "acknowledgement" can be made public
     * If a document is public, it will show up in "Company Docs" and be visible to all employees
     */
    get isPublic(): boolean {
        // All signing documents are private (standard, offer letter)
        if (this.isSigningDocument()) {
            return false;
        }

        return this._attributes['isPublic'];
    }

    set isPublic(val: boolean) {
        this._attributes['isPublic'] = val;
    }

    get signingTemplate(): SigningTemplate {
        return this.hasOne(SigningTemplate, 'signingTemplate');
    }

    get documentAcknowledgements(): DocumentAcknowledgement[] {
        return this.hasMany(DocumentAcknowledgement, 'documentAcknowledgements');
    }

    get documentTags(): DocumentTag[] {
        return this.hasMany(DocumentTag, 'documentTags');
    }

    /**
     * @deprecated Use Type
     * This is being used for display currently
     */
    get docType(): LegacyDocumentType {
        if (this.type === DocumentType.acknowledgement) {
            return 'View Only';
        }

        return this.isOfferLetter() ? 'Offer Letter Template' : 'Signing Template';
    }

    get type(): DocumentType {
        return this._attributes['type'];
    }

    set type(val: DocumentType) {
        this._attributes['type'] = val;
    }

    get assignmentsCount(): number {
        return this._attributes['assignmentsCount'];
    }

    get assignmentsCompletedCount(): number {
        return this._attributes['assignmentsCompletedCount'];
    }

    get assignmentsIncompleteCount(): number {
        return this.assignmentsCount - this.assignmentsCompletedCount;
    }

    get uploadedBy(): Employee | null {
        return this.file ? this.file.uploadedBy : null;
    }

    get file(): File {
        return this.hasOne(File, 'file');
    }

    get employeesWithPendingAssignments(): Employee[] {
        return this.hasMany(Employee, 'employeesWithPendingAssignments');
    }

    get formattedAssignmentsText(): string {
        if (this.type === DocumentType.standardSigningDocument) {
            return `Signed by ${this.assignmentsCompletedCount} of ${this.assignmentsCount} employees`;
        }

        if (this.type === DocumentType.acknowledgement) {
            return `Acknowledged by ${this.assignmentsCompletedCount} of ${this.assignmentsCount} employees`;
        }

        if (this.type === DocumentType.offerLetter) {
            return `Assigned to ${this.assignmentsCount} ${this.assignmentsCount === 1 ? 'candidate' : 'candidates'}`;
        }

        return '-';
    }

    canBeAssigned(): boolean {
        return !this.isSigningDocument() || this.hasSigningTemplateConfigured();
    }

    hasIncompleteAssignments(): boolean {
        return this.assignmentsCount - this.assignmentsCompletedCount > 0;
    }

    isSigningDocument(): boolean {
        return SIGNING_DOCUMENT_TYPES.includes(this.type);
    }

    isStandardSigningDocument(): boolean {
        return this.type === DocumentType.standardSigningDocument;
    }

    isOfferLetter(): boolean {
        return this.type === DocumentType.offerLetter;
    }

    isDocumentAcknowledgement(): boolean {
        return this.type === DocumentType.acknowledgement;
    }

    isViewOnly(): boolean {
        return this.type === DocumentType.viewOnly;
    }

    hasSigningTemplateConfigured(): boolean {
        return this.signingTemplate && this.signingTemplate.hasHelloSignTemplate();
    }

    isBulkAssignable(): boolean {
        if (this.isDocumentAcknowledgement()) {
            return true;
        }

        return this.signingTemplate.isBulkAssignable();
    }

    isTemplateEditable(): boolean {
        if (!this.isSigningDocument()) {
            return true;
        }

        return this.signingTemplate.isReady || this.signingTemplate.helloSignTemplateId === null;
    }

    notify(assignment?: SignatureRequest | DocumentAcknowledgement, isGroup = true): Promise<any> {
        const appendPath = `${this.id}/notify`;

        if (!assignment) {
            return CompanyDocument.find(appendPath);
        }

        if (assignment instanceof DocumentAcknowledgement) {
            return CompanyDocument.where('reminderId', assignment.employeeId).find(appendPath);
        }

        return CompanyDocument.where('reminderId', assignment.id)
            .where('isGroup', isGroup ? 1 : 0) // PHP Validates against boolean, cannot be actual true/false
            .find(appendPath);
    }

    assignAndUnassign(selected: Employee[]): Promise<any> {
        /**
         * maxChunkSize is the number of assignments we will do at one.
         * At some point we decided to chunk these requests.
         * It's not clear why we did this. Regardless, locally we can only do 5 at a time
         * because of a testing limitation imposed by HelloSign.
         */
        const maxChunkSize = inLocal() ? 5 : 10;

        const existingIds = this.employeesWithPendingAssignments.map((employee: Employee) => employee.id);
        const selectedIds = selected.map((employee: Employee) => employee.id);

        const detach: number[] = existingIds.filter((employeeId: number) => !selectedIds.includes(employeeId));

        const attach: number[] = selectedIds.filter((employeeId: number) => !existingIds.includes(employeeId));

        const attachPromises: Promise<CompanyDocument>[] = arrayChunk(attach, maxChunkSize).map((attachChunk) =>
            this.attach('employees', attachChunk).save()
        );

        const detachPromise = this.detach('employees', detach).save();

        return Promise.all([...attachPromises, detachPromise]);
    }
}
