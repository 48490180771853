import { AmeegoHumiImportService } from './ameego-humi-import.service';
import { BenefitEmployeesCsvService } from './benefit-employees-csv.service';
import { CheckForSchedulingImportService } from './check-for-scheduling-import.service';
import { CheckForWcbBackfillService } from './check-for-wcb-backfill.service';
import { TimeOffIntegratedCompensationVacationPayService } from './compensation-vacation-pay.service';
import { DeductionEmployeesCsvService } from './deduction-employees-csv.service';
import { DentalBenefitService } from './dental-benefit/dental-benefit.service';
import { HoursImporterService } from './hours-importer.service';
import { ImportAdditionalIncomesService } from './import-additional-incomes.service';
import { InsurableHoursService } from './insurable-hours.service';
import {
    PayrollBenefitsMenuService,
    PayrollDeductionsMenuService,
    PayrollModuleMenuService,
} from './payroll-module-menu.service';
import { PayrollService } from './payroll.service';
import { PaystubLazyLoadService } from './paystub-lazy-load.service';
import { Rl1BoxService } from './rl1Box.service';
import { SelfOnboardingDocumentsService } from './self-onboarding-documents.service';
import { PayrollSelfOnboardingService } from './self-onboarding.service';
import { CompanyInformationService } from './self-onboarding/company-information.service';
import { T4NotificationsService } from './t4-notifications.service';
import { T4Service } from './t4.service';
import { T4BoxService } from './t4Box.service';
import { BenefitTable } from './tables/benefit-table/benefit.table';
import { DeductionTable } from './tables/deduction-table/deduction.table';
import { DentalBenefitsTable } from './tables/dental-benefits-table/dental-benefits.table';
import { T4SummaryTable } from './tables/deprecated-t4-summary-table/deprecated-t4-summary.table';
import { EmployeeBenefitTable } from './tables/employee-benefit-table/employee-benefit.table';
import { EmployeeDeductionTable } from './tables/employee-deduction-table/employee-deduction.table';
import { EmployeeHourTable } from './tables/employee-hour-table/employee-hour.table';
import { EmployeeTaxFormsTable } from './tables/employee-tax-forms-table/employee-tax-forms.table';
import { EmployerPackageTable } from './tables/employer-package-table/employer-package.table';
import { RoeV2Table } from './tables/roe-table-v2/roe-v2.table';
import { T4IndexTable } from './tables/t4-table/t4-index.table';
import { TaxReturnsTable } from './tables/tax-returns-table/tax-returns.table';
import { WorkersCompensationTable } from './tables/workers-compensation-table/workers-compensation.table';
import { TaxAdjusterService } from './tax-adjuster.service';
import { TimeOffConflictService } from './time-off-conflict.service';
import { VacationPayoutService } from './vacation-payout.service';
import { VacationYtdAdjusterService } from './vacation-ytd-adjuster.service';
import { WorkersCompensationPerLineItemReportService } from './workers-compensation-per-line-item-report.service';
import { WorkersCompensationService } from './workers-compensation.service';

const Services = [
    T4Service,
    HoursImporterService,
    ImportAdditionalIncomesService,
    AmeegoHumiImportService,
    PayrollService,
    PaystubLazyLoadService,
    Rl1BoxService,
    T4BoxService,
    TimeOffConflictService,
    TimeOffIntegratedCompensationVacationPayService,
    VacationYtdAdjusterService,
    BenefitEmployeesCsvService,
    CheckForSchedulingImportService,
    CheckForWcbBackfillService,
    CompanyInformationService,
    DeductionEmployeesCsvService,
    VacationPayoutService,
    PayrollSelfOnboardingService,
    SelfOnboardingDocumentsService,
    T4NotificationsService,
    TaxAdjusterService,
    InsurableHoursService,
    WorkersCompensationService,
    WorkersCompensationPerLineItemReportService,
    PayrollModuleMenuService,
    DentalBenefitsTable,
    DentalBenefitService,
    PayrollBenefitsMenuService,
    PayrollDeductionsMenuService,
];

export {
    AmeegoHumiImportService,
    BenefitEmployeesCsvService,
    BenefitTable,
    CheckForSchedulingImportService,
    CheckForWcbBackfillService,
    CompanyInformationService,
    DeductionEmployeesCsvService,
    DeductionTable,
    EmployeeBenefitTable,
    EmployeeDeductionTable,
    EmployeeHourTable,
    EmployeeTaxFormsTable,
    EmployerPackageTable,
    HoursImporterService,
    ImportAdditionalIncomesService,
    InsurableHoursService,
    PayrollBenefitsMenuService,
    PayrollDeductionsMenuService,
    PayrollSelfOnboardingService,
    PayrollService,
    PaystubLazyLoadService,
    Rl1BoxService,
    RoeV2Table,
    SelfOnboardingDocumentsService,
    Services,
    T4BoxService,
    T4IndexTable,
    T4NotificationsService,
    T4Service,
    T4SummaryTable,
    TaxAdjusterService,
    TaxReturnsTable,
    TimeOffConflictService,
    TimeOffIntegratedCompensationVacationPayService,
    VacationPayoutService,
    VacationYtdAdjusterService,
    WorkersCompensationPerLineItemReportService,
    WorkersCompensationService,
    WorkersCompensationTable,
};
