import { Model } from '@models/core/base.model';
import { Employee } from '@models/employee/employee.model';

export class OnboardingReminder extends Model {
    protected static _resource = 'companyManagement/companies/:company/onboardingReminders';

    protected static _version = 'v2';

    protected static _serializeAttributes = ['companyId', 'name', 'body', 'remindTo', 'delayDays', 'remindBefore'];

    colleagueId: string | null;

    get companyId(): string | null {
        return this._attributes['companyId'] || null;
    }

    set companyId(val: string) {
        this._attributes['companyId'] = val;
    }

    get name(): string | null {
        return this._attributes['name'] || null;
    }

    set name(val: string) {
        this._attributes['name'] = val;
    }

    get body(): string | null {
        return this._attributes['body'] || null;
    }

    set body(val: string) {
        this._attributes['body'] = val;
    }

    /**
     * Who to send the reminder to (Employee, Manager, Administrator)
     */
    get remindTo(): string | null {
        return this._attributes['remindTo'] || null;
    }

    set remindTo(val: string | null) {
        this._attributes['remindTo'] = val;
    }

    /**
     * Remind Before Hiring Date Flag
     */
    get delayDays(): number | null {
        if (this._attributes['delayDays'] || this._attributes['delayDays'] === 0) {
            return this._attributes['delayDays'];
        }

        return null;
    }

    set delayDays(val: number) {
        this._attributes['delayDays'] = val;
    }

    get remindBefore(): boolean {
        return this._attributes['remindBefore'];
    }

    set remindBefore(val: boolean) {
        this._attributes['remindBefore'] = val;
    }

    get remindToName(): string | null {
        return Number.isNaN(+this.remindTo) ? this.remindTo : 'colleague';
    }

    /**
     * Cycle through the reminders and, if an employee id is found in the
     * remindTo property, fetch the employee model and store them in the remindTo property.
     * Finally, store all the populated reminders on the object.
     */
    static populateReminders(reminders: OnboardingReminder[]): Promise<OnboardingReminder[]> {
        const populatedReminders: OnboardingReminder[] = [];
        const promises = reminders.map((reminder) => {
            if (Number(reminder.remindTo) > 0) {
                return Employee.param('company', reminder.companyId)
                    .with('account')
                    .find(reminder.remindTo)
                    .then((employee) => {
                        reminder.remindTo = employee.fullName;
                        populatedReminders.push(reminder);
                    })
                    .catch((err) => {
                        throw err;
                    });
            }

            populatedReminders.push(reminder);
            return;
        });

        return Promise.all(promises).then(() => {
            return populatedReminders;
        });
    }
}
