import { AfterContentInit, Component, ElementRef, HostBinding, Input } from '@angular/core';
import { BadgeOptions } from '@app/interfaces';
import { MenuService } from '@app/services/menu/menu.service';
import { Translatable } from '@app/types/translatable.type';

type ContextMenuType = 'semantic' | 'material';

@Component({
    selector: 'app-header',
    templateUrl: './header.template.html',
    styleUrls: ['./header.style.scss'],
})
export class HeaderComponent implements AfterContentInit {
    @Input() menuService: MenuService;
    @Input() title: Translatable = 'components.header.title';
    @Input() noTitle = false;
    @Input() subtitle: Translatable;
    @Input() back = false;
    @Input() backRoute: string | string[];
    @Input() contextMenu = false;
    @Input() contextMenuType: ContextMenuType = 'semantic';
    @Input() badgeOptions?: BadgeOptions;
    @Input() hideOverflow = false;
    @Input() onBackClick?: () => void;

    // makes our title attribute not show up in the tooltip
    // https://stackoverflow.com/a/74510560
    @HostBinding('attr.title') get getTitle(): null {
        return null;
    }

    date: Date = new Date();

    constructor(private element: ElementRef<HTMLElement>) {}

    ngAfterContentInit(): void {
        setTimeout(() => {
            $(this.element.nativeElement).find('.ui.dropdown').dropdown();
        });
    }
}
