import { Paginator } from '@app/classes';
import { Table } from '@app/modules/table/classes/table.class';
import { AuthService } from '@app/services';
import { T4Summary } from '@models/payroll/t4-summary.model';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';
import { NonGeneratedTaxReturnPaginator } from '../tax-returns-table/tax-returns.table';
import { GeneratedOnColumn, StatusColumn, TitleColumn } from './columns';

/**
 * @deprecated Does not allow for RL-1 flow. Use [TaxReturnsTable](../tax-returns-table/tax-returns.table.ts) instead
 */
export class T4SummaryTable extends Table<T4Summary> {
    links = true;

    constructor(
        protected auth: AuthService,
        public year: number,
        private translateService: TranslateService
    ) {
        super(auth);
        this.boot();
    }

    setColumns(): void {
        this.columns = [new TitleColumn(this.year, this.translateService), new GeneratedOnColumn(), new StatusColumn()];
    }

    /**
     * Attempt to load the T4Summary for the year.
     *
     * If the index is empty, swap the paginator for an instance of NonGeneratedTaxReturnPaginator,
     * so that we have our default row(s) in the table
     */
    setDataSource(): void {
        this.onLoading.pipe(filter((loading) => !loading)).subscribe(() => {
            if (this.paginator?.empty) {
                this.paginator = new NonGeneratedTaxReturnPaginator([new T4Summary()]);
            }
        });

        this.paginator = new Paginator(
            T4Summary.param('company', this.auth.company?.id).where('year', this.year.toString())
        );
    }

    getLink(t4Summary: T4Summary): string[] {
        return ['/payroll/records/tax-returns', this.year.toString(), t4Summary.id?.toString() ?? 'new'];
    }
}
