import { Model } from '../core/base.model';

/**
 * Supports more fields, but I don't want to maintain the full list here since it's semi-dynamic json.
 */
type StatementConfiguration = {
    fileType: string;
    certificateFieldName: string;
};

export class BenefitProvider extends Model {
    protected static _resource = 'benefitManagement/benefitProviders';

    protected static _version = 'v2';

    get name(): string {
        return this._attributes['name'];
    }

    /**
     * @deprecated
     * This appears to no longer be used by the UI
     * If you discover otherwise, please remove this comment and
     * explain how it is used
     */
    get color(): string {
        return this._attributes['color'];
    }

    get logoUrl(): string {
        return this._attributes['logoUrl'];
    }

    get adminPortalUrl(): string {
        return this._attributes['adminPortalUrl'];
    }

    get portalUrl(): string {
        return this._attributes['portalUrl'];
    }

    get code(): string {
        return this._attributes['code'];
    }

    get statementConfiguration(): StatementConfiguration | null {
        return this._attributes['statementConfiguration'];
    }
}
