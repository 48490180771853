<h2 class="no-margin" id="dialog-header">{{ header() | translate }}</h2>
<div class="content">
    <!-- Only show a column if there are any entries in it, otherwise just display one column-->
    @if (removals().length) {
        <ng-container
            [ngTemplateOutlet]="column"
            [ngTemplateOutletContext]="{ employees: removals(), text: removalsText(), icon: 'remove' }"
        ></ng-container>
    }
    @if (additions().length) {
        <ng-container
            [ngTemplateOutlet]="column"
            [ngTemplateOutletContext]="{ employees: additions(), text: additionsText(), icon: 'complete' }"
        ></ng-container>
    }
</div>

<div class="footer">
    <div class="buttons">
        <ui-button class="margin-right-auto" type="link" (click)="performAction('BACK')">
            <mat-icon svgIcon="chevronLeft" />
            {{ 'components.bulk-select-confirmation.defaults.back' | translate }}</ui-button
        >
        <ui-button (click)="performAction('CANCEL')">{{ 'cancel' | translate }}</ui-button>
        <ui-button type="primary" (click)="performAction('CONFIRM')">{{
            'components.bulk-select-confirmation.defaults.confirm' | translate
        }}</ui-button>
    </div>
</div>

<ng-template #column let-employees="employees" let-text="text" let-icon="icon">
    <div class="flex col">
        <h3 [id]="icon"><mat-icon [svgIcon]="icon" />{{ text | translate: getTranslationParams(employees) }}</h3>
        <ul [attr.aria-labelledby]="icon">
            @for (employee of employees; track employee.id) {
                <li [attr.aria-label]="employee?.fullName">
                    <app-avatar class="w-100" [employee]="employee" size="font-fixed" [name]="true" />
                </li>
            }
        </ul>
    </div>
</ng-template>
