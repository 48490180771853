import { SigningTemplateSlotNames } from '@app/modules/documents-v2/enums/signing-template-slot-names.enum';
import { Model } from '../core/base.model';
import { CompanyDocument } from './company-document.model';
import { SigningTemplateSlot } from './signing-template-slot.model';

export class SigningTemplate extends Model {
    protected static _resource = 'companyDocuments/:companyDocument/signingTemplates';
    protected static _version = 'v2';
    protected static _serializeAttributes = ['helloSignTemplateId', 'helloSignUpdatedAt', 'cosigners'];
    protected static _datetimes = ['helloSignUpdatedAt'];

    get helloSignTemplateId(): string {
        return this._attributes['helloSignTemplateId'];
    }

    set helloSignTemplateId(val: string) {
        this._attributes['helloSignTemplateId'] = val;
    }

    get isReady(): boolean {
        return this._attributes['isReady'];
    }

    get helloSignUpdatedAt(): Date {
        return this._attributes['helloSignUpdatedAt'];
    }

    set helloSignUpdatedAt(val: Date) {
        this._attributes['helloSignUpdatedAt'] = val;
    }

    get editUrl(): string {
        return this._attributes['editUrl'];
    }

    set cosigners(val: number) {
        this._attributes['cosigners'] = val;
    }

    // this is being accessed.
    // don't touch it unless you know what it's doing
    get cosigners(): number {
        return this._attributes['cosigners'];
    }

    get slots(): SigningTemplateSlot[] {
        return this.hasMany(SigningTemplateSlot, 'slots');
    }

    set slots(val: SigningTemplateSlot[]) {
        this.setMany('slots', val);
    }

    /* Is bulk assignable if it only has 1 slot */
    isBulkAssignable(): boolean {
        return this.slots.length === 1;
    }

    isMultiSigner(): boolean {
        return !this.isBulkAssignable();
    }

    hasHelloSignTemplate(): boolean {
        return Boolean(this.helloSignTemplateId);
    }

    hasCosigners(): boolean {
        const cosignerTypes = [
            SigningTemplateSlotNames.PrimaryCosigner,
            SigningTemplateSlotNames.SecondaryCosigner,
            SigningTemplateSlotNames.TertiaryCosigner,
        ];

        return this.slots.some((slot) => cosignerTypes.includes(slot.name));
    }

    /**
     * Returns a count of custom fields assigned to the signing templates slots
     * Throws if the model is in an invalid state for getting the number of custom fields
     */
    numberOfCustomFields(): number {
        const isInValidState = this.isPersisted && this.hasHelloSignTemplate() && this.slots?.length;

        if (!isInValidState) {
            throw new Error(`Signing template (id=${this.id ?? 0}) is not in a valid state for fetching custom fields`);
        }

        let countOfCustomFields = 0;

        this.slots.forEach((slot: SigningTemplateSlot) => (countOfCustomFields += slot.customFieldCount()));

        return countOfCustomFields;
    }

    /**
     * Not all signing templates have company documents
     */
    get document(): CompanyDocument | null {
        return this.hasOne(CompanyDocument, 'document') ?? null;
    }

    set document(companyDocument: CompanyDocument | null) {
        this.setOne('document', companyDocument);
    }
}
